import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../shared/storage/local-storage.service';
import {AuthService} from '../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from './User';
import {Clipboard} from '@angular/cdk/clipboard';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MonthFormatPipe} from '../../../shared/pipes/month-format.pipe';
import {NgxSpinnerService} from 'ngx-spinner';

const moment = require('moment');

export interface Pagination<T> {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  data: T[];
}

@Component({
  selector: 'app-customer-leads',
  templateUrl: './customer-leads.component.html',
  styleUrls: ['./customer-leads.component.scss']
})
export class CustomerLeadsComponent implements OnInit {

  userData: any;
  customer_leads = [];
  selectedScript: any;
  customers = [];
  page = 1;
  maxSize = 0;
  selectedUser: User;
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  selectedState = null;
  showpwd = false;
  showConfirmPassword = false;
  data: any[] = []; // Your complete dataset
  paginatedData: any[] = []; // Data for the current page
  currentPage = 1;
  itemsPerPage = 10;
  model = null;
  totalPages = 0;
  dob = '';
  userRegisteration = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(' '),
    lastName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required]),
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });
  submitted = false;
  platform: any;
  pharmacyUser: any;
  pharmacy: any;
  userStatus: any;
  maxDate = {
    year: Number(new Date().getFullYear()),
    month: Number(new Date().getMonth()),
    day: Number(new Date().getDay()),
  }

  constructor(private storage: LocalStorageService, private monthPipe: MonthFormatPipe,
              private modalService: NgbModal, private clipboard: Clipboard,
              private spinner: NgxSpinnerService,
              private authService: AuthService, private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<any> {
    await this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.authService.fetch_particular_pharmacy(this.userData).then(async (resp) => {
      this.pharmacy = resp;
    });
    await this.fetchScripts_leads();
  }

  async fetchScripts_leads() {
    await this.authService.getScripts_leads(this.userData, 'true').then((response: any) => {
      if (response.status === 404) {
        this.customer_leads = [];
        return;
      }
      this.customer_leads = response.data.map((data) => {
        data.firstInitial = data.firstName.charAt(0).toUpperCase();
        data.lastInitial = data.lastName.charAt(0).toUpperCase();
        return data;
      });
    }).catch(err => {
      this.customer_leads = [];
    })
  }


  get rf() {
    return this.userRegisteration.controls;
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
    this.toastr.clear();
    this.toastr.info('Token copied', 'Info', {
      positionClass: 'toast-top-center'
    })
  }

  async fetchUsers() {
    await this.authService.getAllUsers(this.userData, 'false', 'true', String(this.page), this.selectedScript.zen).then((response: any) => {
      if (response.status === 500) {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      if (response.response === 'The total user length is zero.') {
        this.customers = [];
        this.maxSize = 0;
        this.NonZenValue();
        return;
      }
      this.customers = response.results;
      this.customers.map(customer => customer.homeAdress = '');
      this.mapAddress();
      this.maxSize = response.totalPages;
    });
  }

  async NonZenValue() {
    await this.authService.getAllUsers(this.userData, 'false', 'true', String(this.page), '',
      this.selectedScript.firstInitial, this.selectedScript.lastName, this.selectedScript.dateOfBirth.split('T')[0]).then((response: any) => {
      if (response.status === 500) {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      if (response.response === 'The total user length is zero.') {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      this.customers = response.results;
      this.customers.map(customer => customer.homeAdress = '');
      this.mapAddress();
      this.maxSize = response.totalPages;
    });
  }


  async openModal(content, script) {
    this.selectedScript = script;
    this.data = [];
    this.customers = [];
    await this.fetchUsers();
    this.selectedUser = null;
    this.modalService.open(content, {size: 'xl', backdrop: 'static'})
  }


  mapAddress() {
    this.customers.map((customer) => {
      if (customer.addresses.length > 0) {
        let temp;
        const checkHome = customer.addresses.some((add) => add.addressType === 'Home');
        const checkWork = customer.addresses.some((add) => add.addressType === 'Work');
        const checkOther = customer.addresses.some((add) => add.addressType === 'Others');
        if (checkHome && checkWork && !checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (checkHome && !checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (checkHome && checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (!checkHome && checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Work');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (checkHome && !checkWork && !checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (!checkHome && checkWork && !checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Work');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (!checkHome && !checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Others');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
      } else {
        customer.homeAdress = ''
      }
    });
  }


  viewDetails(modal, size = 'xl') {
    this.modalService.open(modal, {size: size, backdrop: 'static'})
  }


  assignScript(modal) {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.assign_script_for_user(this.userData, this.selectedUser._id, this.selectedScript._id).then(async (response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      this.toastr.clear();
      this.toastr.success('User assigned to script and zen value cleared', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      modal.dismiss('Cross-Click');
      await this.fetchScripts_leads();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.clear();
      this.spinner.hide();
      this.toastr.error('Internal server error', 'Error', {
        positionClass: 'toast-top-center'
      })
    });
  }


  async onSubmit(modal, checkBox) {
    this.submitted = true;
    if (this.userRegisteration.invalid) {
      return;
    }

    if (this.userRegisteration.controls['password'].value !== this.userRegisteration.controls['confirm_password'].value) {
      this.toastr.error('Password mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.model !== null) {
      if (moment(this.model, 'DD-MM-YYYY').format() === 'Invalid date') {
        this.dob = moment(this.monthPipe.transform(this.model)).format();
      } else {
        this.dob = moment(this.model, 'DD-MM-YYYY').format();
      }
    }

    let TACAccepted = false;
    let PSAccepted = false;
    if (!checkBox.checked) {
      this.toastr.error('Please accept the terms and conditions', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      TACAccepted = true;
      PSAccepted = true;
    }

    const body = {
      'firstName': this.userRegisteration.value.firstName,
      'middleName': this.userRegisteration.value.middleName,
      'lastName': this.userRegisteration.value.lastName,
      'email': this.userRegisteration.value.email,
      'password': this.userRegisteration.value.password,
      'number': '+61' + parseInt(this.userRegisteration.value.number, 10),
      'dateOfBirth': this.dob,
      'PSAccepted': PSAccepted,
      'TACAccepted': TACAccepted,
      'consentAccepted': false,
      'twofaEnabled': false,
      'role': 'User',
      'userType': 'user',
      'platformId': this.platform._id
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.register_new_user(body).then(async (resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('User already Exits', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      // this.selectedUser = resp.userCreated;

      const pharmacyUser = {
        userId: resp.userCreated._id,
        pharmacyId: this.pharmacy._id
      }
      await this.authService.createPharmacy_user(this.userData, pharmacyUser).then(async (response) => {
        this.pharmacyUser = response.pharmacyUser_created;
        this.userStatus = response.pharmacyUser_created.Status;
        const addressBody = {
          userId: resp.userCreated._id,
          addressType: this.userRegisteration.value.addressType,
          address: this.userRegisteration.value.address,
          suburb: this.userRegisteration.value.suburb,
          state: this.userRegisteration.value.state,
          // tslint:disable-next-line:radix
          postcode: parseInt(this.userRegisteration.value.postcode)
        }
        await this.authService.create_Address(addressBody).then(async (addressResp: any) => {
          const updateAddress = [{
            propName: 'user_address',
            value: addressResp.address._id
          }]
          await this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, updateAddress).then((result) => {
          });
          await this.authService.fetchCustomer(this.userData, resp.userCreated._id).then((customer: any) => {
            this.customers.push(customer);
            this.customers.map(cust => cust.homeAdress = '');
            this.mapAddress();
          });
        });
      }).catch(err => {
        this.toastr.error('Failed to create Pharmacy User', 'Error!', {
          positionClass: 'toast-top-center'
        });
      });
      this.toastr.clear();
      this.toastr.success('User registered successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      this.submitted = false;
      this.userRegisteration.reset();
      this.spinner.hide();
    }).catch(err => {
      this.toastr.clear();
      this.spinner.hide();
      this.toastr.error('User registration failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
    modal.dismiss('Cross-Click')
  }

  openRegisModal(modal) {
    this.showpwd = false;
    this.selectedUser = null;
    this.showConfirmPassword = false;
    this.submitted = false;
    this.userRegisteration.reset();
    this.modalService.open(modal, {size: 'xl', backdrop: 'static'});
  }

  changeDob(date: any) {
    this.dob = moment(this.monthPipe.transform(date)).format();
  }

}
