import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {
  ModalDismissReasons,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct, NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {Json2csvService} from '../../../../shared/services/json2csv.service';
import {PdfServiceService} from '../../../../shared/services/pdf-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
// @ts-ignore
const moment = require('moment');
const momentTimezone = require('moment-timezone');


@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {

  showcalendar = false;
  date = new Date();
  userData: any;
  pharmacy: any;
  auditLogs = [];
  dateRangeLogs = [];
  todayDay: any;
  formattedDate: any;
  dateSearch = new Date();
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  from: string;
  to: string;
  isCollapsed = true;
  selectedOrder: any
  closeResult = '';
  rows: any[] = [];
  page = 1;
  pageChanged = false;
  maxSize = 0;
  totalCount = 0;
  tableData = ['administrators', 'medications', 'orderitems', 'orders', 'orderupdates', 'otps', 'orderpayments', 'pharmaadministrators', 'pharmacies', 'platform', 'pharmacyproductcategories', 'pharmacyproducts', 'pharmacyusers', 'prescriptions', 'roles', 'scripts', 'usages', 'userimages', 'verifications', 'eRx', 'issuecategory'];
  selectedEnum = '';
  calendarChoosed = false;

  constructor(private authService: AuthService, private monthPipe: MonthFormatPipe,
              private pdfService: PdfServiceService, private spinner: NgxSpinnerService,
              private modalService: NgbModal, private jsonCSv: Json2csvService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('script-capture'));
    this.from = moment().format('YYYY-MM-DD');
    this.to = moment().format('YYYY-MM-DD');
    this.formattedDate = momentTimezone(this.monthPipe.transform(this.dateSearch)).tz('asia/kolkata').format();
    this.authService.fetch_audit_logs(this.userData, '', '', this.page).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.maxSize = resp.totalPages;
      this.auditLogs = resp.data;
      this.totalCount = resp.totalCount;
    }).catch(err => {
      if (err.status === 404) {
        this.auditLogs = [];
        this.maxSize = 0;
        this.totalCount = 0;
        return;
      }
    });
  }

  onDateSelection(date: NgbDate) {
    this.page = 1;
    this.calendarChoosed = true;
    this.pageChanged = false;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.toDate !== undefined && this.toDate !== null) {
      const x = moment(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day).format('YYYY-MM-DD');
      const {day, month, year} = this.toDate;
      const y = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
      this.from = x;
      this.to = y;
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.authService.fetch_audit_logs(this.userData, x,
        y, -1, this.selectedEnum).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.clear();
          this.spinner.hide();
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.maxSize = resp.totalPages;
        this.spinner.hide();
        this.auditLogs = resp.data;
        this.dateRangeLogs = resp.data;
      }).catch(err => {
        if (err.status === 404) {
          this.auditLogs = [];
          this.spinner.hide();
          this.maxSize = 0;
          this.toastr.clear();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    }

  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  downloadAll() {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.fetch_audit_logs(this.userData, '', '', -1, this.selectedEnum).then((resp: any) => {
      this.spinner.hide();
      const temp = [];
      let body: any = {}
      resp.data.filter(log => {
        body.Description = log.change;
        body.ChangedBy = log.changedBy;
        body.Date = log.changedDate.split('T')[0].split('-').reverse().join('-');
        body.Time = moment(log.createdAt).format('LT');
        body.Table = log.table;
        body.Record = log.recordId;
        body.User = log.changedByUser;
        temp.push(body);
        body = {};
      });
      // this.jsonCSv.downloadCsv(temp, 'AuditLogs')
      this.pdfService.generatePdf(temp);
    }).catch(err => {
      this.spinner.hide();
      if (err.status === 404) {
        this.toastr.clear();
        this.toastr.error('No logs found', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    })
  }


  OnChangeEnums(event: Event) {
    this.selectedEnum = (event.target as HTMLInputElement).value;
    if (this.calendarChoosed) {
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.authService.fetch_audit_logs(this.userData, this.from, this.to, -1, this.selectedEnum).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.clear();
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
          return;
        }
        this.spinner.hide();
        this.auditLogs = resp.data;
        this.dateRangeLogs = resp.data;
        this.maxSize = 0;
      }).catch(err => {
        if (err.status === 404) {
          this.auditLogs = [];
          this.maxSize = 0;
          this.toastr.clear();
          this.spinner.hide();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
      return;
    }
    this.authService.fetch_audit_logs(this.userData, this.from,
      this.to, this.page, this.selectedEnum).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.maxSize = resp.totalPages;
        this.auditLogs = resp.data;
        this.totalCount = resp.totalCount;
      }
    }).catch(err => {
      if (err.status === 404) {
        this.auditLogs = [];
        this.totalCount = 10;
        this.maxSize = 0;
        this.toastr.clear();
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    });
  }

  pagination(page: number) {
    this.page = page;

    if (this.calendarChoosed || this.selectedEnum !== '') {
      this.authService.fetch_audit_logs(this.userData, this.from, this.to, this.page, this.selectedEnum).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.clear();
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        } else {
          this.auditLogs = resp.data;
          this.maxSize = resp.totalPages;
        }
      }).catch(err => {
        if (err.status === 404) {
          this.auditLogs = [];
          this.maxSize = 0;
          this.toastr.clear();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    } else {
      this.pageChanged = true;
      this.authService.fetch_audit_logs(this.userData, '',
        '', this.page, this.selectedEnum).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.clear();
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        } else {
          this.auditLogs = resp.data;
          this.maxSize = resp.totalPages;
        }
      }).catch(err => {
        if (err.status === 404) {
          this.auditLogs = [];
          this.maxSize = 0;
          this.toastr.clear();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    }

  }

  currentPage() {
    this.spinner.hide();
    const temp = [];
    let body: any = {}
    this.auditLogs.filter(log => {
      body.Description = log.change;
      body.ChangedBy = log.changedBy;
      body.Date = log.changedDate.split('T')[0].split('-').reverse().join('-');
      body.Time = moment(log.createdAt).format('LT');
      body.Table = log.table;
      body.Record = log.recordId;
      body.User = log.changedByUser;
      temp.push(body);
      body = {};
    });
    // this.jsonCSv.downloadCsv(temp, 'AuditLogs')
    this.pdfService.generatePdf(temp);
  }

  dateRange() {
    const temp = [];
    let body: any = {}
    this.dateRangeLogs.filter(log => {
      body.Description = log.change;
      body.ChangedBy = log.changedBy;
      body.Date = log.changedDate.split('T')[0].split('-').reverse().join('-');
      body.Time = moment(log.createdAt).format('LT');
      body.Table = log.table;
      body.Record = log.recordId;
      body.User = log.changedByUser;
      temp.push(body);
      body = {};
    });
    // this.jsonCSv.downloadCsv(temp, 'AuditLogs')
    this.pdfService.generatePdf(temp);
  }
}
