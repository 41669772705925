import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PharmacyDashboardComponent} from './pharmacy/pharmacy-dashboard/pharmacy-dashboard.component';
import {OrdersComponent} from './pharmacy/orders/orders.component';
import {OrdersHistoryComponent} from './pharmacy/orders-history/orders-history.component';
import {CustomersComponent} from './pharmacy/customers/customers.component';
import {SettingsComponent} from './pharmacy/settings/settings.component';
import {ListProductComponent} from './pharmacy/list-product/list-product.component';
import {StaffComponent} from './pharmacy/staff/staff.component';
import {ListPharmacyComponent} from './pharmacy/list-pharmacy/list-pharmacy.component';
import {NewOrderComponent} from './pharmacy/new-order/new-order.component';
import {CustOrderHistoryComponent} from './pharmacy/cust-order-history/cust-order-history.component';
import {ChangePasswordComponent} from './pharmacy/change-password/change-password.component';
import {EditProfileComponent} from './pharmacy/edit-profile/edit-profile.component';
import {CategoriesComponent} from './pharmacy/categories/categories.component';
import {AuditLogsComponent} from './pharmacy/audit-logs/audit-logs.component';
import {PharmacyInfoComponent} from './pharmacy/pharmacy-info/pharmacy-info.component';
import {GlobalProdListComponent} from './pharmacy/global-prod-list/global-prod-list.component';
import {PlatformComponent} from './platform/platform.component';
import {MyScriptsComponent} from './pharmacy/my-scripts/my-scripts.component';
import {UpcomingOrdersComponent} from './pharmacy/upcoming-orders/upcoming-orders.component';
import {MailsFromPharmacyComponent} from './pharmacy/mails-from-pharmacy/mails-from-pharmacy.component';
import {ProductPageComponent} from './pharmacy/product-page/product-page.component';
import {OrdersMobileviewPageComponent} from './pharmacy/orders-mobileview-page/orders-mobileview-page.component';
import {CustomerLeadsComponent} from './customer-leads/customer-leads.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'pharmacy-dashboard',
        component: PharmacyDashboardComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'new-order',
        component: NewOrderComponent,
        data: {
          title: 'Dashboard'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'orders',
        component: OrdersComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'cust-order-history',
        component: CustOrderHistoryComponent,
        data: {
          title: 'Dashboard'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'orders-history',
        component: OrdersHistoryComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'customer',
        component: CustomersComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'upcoming-orders',
        component: UpcomingOrdersComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'list',
        component: ListProductComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'staffs',
        component: StaffComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'pharmacy',
        component: ListPharmacyComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'edit-profile',
        component: EditProfileComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'product-page',
        component: ProductPageComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'categories',
        component: CategoriesComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'audit-logs',
        component: AuditLogsComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'pharmacy-info',
        component: PharmacyInfoComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'global-prod-list',
        component: GlobalProdListComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'platform',
        component: PlatformComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'my-scripts',
        component: MyScriptsComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'mails-from-pharmacy',
        component: MailsFromPharmacyComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'order-view',
        component: OrdersMobileviewPageComponent,
        data: {
          title: 'Pharmacy-Dashboard'
        }
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'customer-leads',
        component: CustomerLeadsComponent,
        data: {
          title: 'Customer Leads'
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FullPagesRoutingModule {
}
