import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const moment = require('moment');

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  submitted = false;
  userData: any;
  fileCount = 0;
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  selectedState = null;
  editProfile = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(''),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    landLineNumber: new FormControl('', [Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  });
  model: NgbDateStruct;


  selectedAddress: any;
  showMessageBlock = true;
  showUpload = false;
  oldDateOfBirth: any;
  file1: any;
  file2: any;
  pharmacyUser: any;
  userAddress: any[] = [];
  selectedPharmacy: any;
  addressForm = new FormGroup({
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });
  dateOfBirth: any;

  constructor(private authService: AuthService, private toastr: ToastrService,
              private monthPipe: MonthFormatPipe, private storage: LocalStorageService,
              private modalService: NgbModal) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.authService.fetchUser(this.userData).then((reps: any) => {
      this.initialize(reps);
    });
    this.loadUserAddress();
  }


  loadUserAddress() {
    this.authService.fetch_user_address(this.userData).then((resp) => {
      this.userAddress = resp;
    })
  }

  get lf() {
    return this.editProfile.controls;
  }

  initialize(user: any) {
    this.editProfile.controls['firstName'].setValue(user.firstName);
    this.editProfile.controls['middleName'].setValue(user.middleName);
    this.editProfile.controls['lastName'].setValue(user.lastName);
    this.editProfile.controls['email'].setValue(user.email);
    this.editProfile.controls['number'].setValue(user.number.split('+61')[1]);
    this.editProfile.controls['landLineNumber'].setValue(user.landLineNumber.split('+61')[1]);
    this.dateOfBirth = user.dateOfBirth.split('T')[0].split('-').reverse().join('-');
    this.oldDateOfBirth = user.dateOfBirth.split('T')[0].split('-').reverse().join('-');
  }


  makeAddressDefault(addr: any, content) {
    this.selectedAddress = addr;
    this.modalService.open(content, {size: 'md'});
  }

  createPharmacyUser() {
    this.showUpload = true;
  }


  saveDocuments(content) {
    this.modalService.dismissAll(content);
  }

  openModal(Address, data = {}, size = 'lg') {
    this.selectedAddress = data;
    if (this.userAddress.length === 3) {
      this.toastr.error('Maximum User address limit exceeded', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.submitted = false;
    this.modalService.open(Address, {size: size});
  }

  openModal1(modal, data) {
    this.selectedAddress = data;
    this.modalService.open(modal, {size: 'md'});
  }

  submitAddress(content) {
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    const body = {
      userId: this.userData.auth.data._id,
      addressType: this.addressForm.value.addressType,
      address: this.addressForm.value.address,
      suburb: this.addressForm.value.suburb,
      state: this.addressForm.value.state,
      postcode: parseInt(this.addressForm.value.postcode)
    }

    this.authService.create_Address(body).then((resp: any) => {
      this.toastr.info('Address created successfully', 'Created', {
        positionClass: 'toast-top-center'
      });
      this.loadUserAddress();
      this.modalService.dismissAll(content);
      this.addressForm.reset();
    }).catch(err => {
      this.toastr.error('Failed to create the address', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }

  changeDob(date: any) {
    this.dateOfBirth = moment(this.monthPipe.transform(date)).format();
  }


  editMyProfile() {
    this.submitted = true;
    if (this.editProfile.invalid) {
      return;
    }

    if (this.model !== undefined) {
      if (moment(this.model, 'DD-MM-YYYY').format() === 'Invalid date') {
        this.dateOfBirth = moment(this.monthPipe.transform(this.model)).format();
      } else {
        this.dateOfBirth = moment(this.model, 'DD-MM-YYYY').format();
      }
    }

    let body = [];
    for (const key in this.editProfile.controls) {
      if (key !== 'number' && key !== 'landLineNumber') {
        if (this.editProfile.controls[key].dirty) {
          body.push({propName: key, value: this.editProfile.controls[key].value})
        }
      } else if (this.editProfile.controls[key].dirty) {
        body.push({
          propName: key,
          value: this.editProfile.controls[key].value === '' ? ' ' : '+61' + this.editProfile.controls[key].value
        })
      }
    }
    if (this.oldDateOfBirth !== this.dateOfBirth) {
      body = [{
        propName: 'dateOfBirth', value: this.dateOfBirth,
      }];
    }

    if (body.length === 0) {
      this.toastr.error('Edit any of the fields.', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.authService.updateUserProfile(this.userData, body).then((resp: any) => {
      this.authService.fetch_user_detail(this.userData).then((res: any) => {
        this.authService.sendResponse(res);
      });
      this.toastr.success('User Updated successfully.', 'Success', {
        positionClass: 'toast-top-center'
      });

    }).catch(err => {
      this.toastr.error('User Updation failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
    });


  }


  deleteAddress(modal) {
    this.authService.deleteAddress(this.userData, this.selectedAddress).then((res) => {
      this.toastr.error('Address deleted successfully', 'Deleted', {
        positionClass: 'toast-top-center'
      });
      this.loadUserAddress();
      modal.dismiss('Cross-Click');
    }).catch(err => {
      this.toastr.error('Failed to delete the address', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }
}
