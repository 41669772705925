<section id="ngx-datatable-filter">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Orders</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-lg-3 col-12 mt-sm-1">
                <select class="form-control mt-1" (change)="OnSearchBy($event)">
                  <option disabled>Select</option>
                  <option *ngFor="let ele of searchBy;" [value]="ele.value">{{ ele.title }}</option>
                </select>
              </div>
              <div class="col-md-4 col-lg-3 col-12 mt-sm-1">
                <fieldset>
                  <div class="input-group mt-1" *ngIf="search.title === 'Name'">
                    <input type="text" #Nametextbox class="form-control" placeholder="Enter Name"
                           (keyup.backspace)="this.searchValue = '';filter(this.globalOrderStatus);"
                           (keyup.enter)="searchOrder(Nametextbox.value)">
                    <div class="input-group-append">
                      <button class="btn light-gray"
                              (click)="searchOrder(Nametextbox.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>

                  <div class="input-group mt-1" *ngIf="search.title === 'Phone Number'">
                    <input type="text" #Phonetextbox class="form-control"
                           (keyup.backspace)="this.searchValue = '';filter(this.globalOrderStatus)"
                           maxlength="10" (keyup.enter)="searchOrder(Phonetextbox.value)"
                           placeholder="Enter Phone Number">
                    <div class="input-group-append">
                      <button class="btn light-gray"
                              (click)="searchOrder(Phonetextbox.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>
                  <div class="input-group mt-1" *ngIf="search.title === 'Email'">
                    <input type="text" #Emailtextbox class="form-control"
                           (keyup.backspace)="this.searchValue = '';filter(this.globalOrderStatus)"
                           (keyup.enter)="searchOrder(Emailtextbox.value)"
                           placeholder="Enter Email">
                    <div class="input-group-append">
                      <button class="btn light-gray"
                              (click)="searchOrder(Emailtextbox.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>
                  <div class="input-group mt-1" *ngIf="search.title === 'Order No'">
                    <input type="text" #OrderNo class="form-control"
                           (keyup.backspace)="this.searchValue = '';filter(this.globalOrderStatus)"
                           (keyup.enter)="searchOrder(OrderNo.value)"
                           placeholder="Enter Order No">
                    <div class="input-group-append">
                      <button class="btn light-gray"
                              (click)="searchOrder(OrderNo.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="col-md-4 col-lg-3 px-sm-2 py-sm-2">
                <div class="checkbox mt-1">
                  <input type="checkbox" #checkBoxChange (change)="OnChangeSearch(checkBoxChange)" id="searchAll">
                  <label for="searchAll">Search All</label>
                </div>
                <!--                <button class="btn primary-btn" (click)="clearSearchFilter()">Clear <i class="ft-refresh-ccw ml-1"></i></button>-->
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12 col-sm-12 px-2 py-1">
                <button class="btn secondary-btn btn-block d-sm-block d-lg-none d-md-none"
                        (click)="openCreateOdrModal(createOrder,'max')"><i class="ft-plus-circle mr-1"></i>Create Order
                </button>
                <button class="btn secondary-btn d-none d-sm-none d-lg-block d-md-block"
                        (click)="openCreateOdrModal(createOrder,'max')"><i class="ft-plus-circle mr-1"></i>Create Order
                </button>
              </div>
              <div class="col-md-6 col-12 col-sm-12 px-2 py-1">
                <div class="d-lg-none d-sm-block">
                  <select class="form-control square text-box mt-sm-1" (change)="OnChangeOrder($event)">
                    <option disabled selected>Select</option>
                    <option value="new">New</option>
                    <option value="pending">Pending</option>
                    <option value="paymentProcess">Payment Process</option>
                    <option value="dispense">Dispense</option>
                    <option value="pendingShipment">Pending Shipment</option>
                    <option value="shipped">Shipped</option>
                    <option value="completed">Completed</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="refund">Refund</option>
                    <option value="issue">Issue</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-12 col-12 d-none d-sm-none d-lg-block">
                <div class="text-right">
                  <button class="btn-status-padding mr-1 btn"
                          [ngClass]="globalOrderStatus === 'new'? 'btn-info btn text-under-line' : 'bg-light-info btn'"
                          (click)="filter('new')">New
                  </button>

                  <button class="mr-1 btn-status-padding btn"
                          [ngClass]="globalOrderStatus === 'pending'?
                          'btn-secondary text-under-line' : 'bg-light-secondary'"
                          (click)="filter('pending')">Pending
                  </button>

                  <button class="mr-1 btn-status-padding btn"
                          [ngClass]="globalOrderStatus === 'paymentProcess' ? 'payment-selected text-under-line' : 'payment'"
                          (click)="filter('paymentProcess')">Payment Process
                  </button>

                  <button class="mr-1 btn-status-padding btn" (click)="filter('dispense')"
                          [ngClass]="globalOrderStatus === 'dispense'? 'navy-dark text-under-line' : 'navy-light'">
                    Dispense
                  </button>

                  <button class="mr-1 btn-status-padding btn"
                          [ngClass]="globalOrderStatus === 'pendingShipment' ? 'shipped-dark text-under-line' :  'shipped-light'"
                          (click)="filter('pendingShipment')">Pending Shipment
                  </button>

                  <button class="btn mr-1 btn-status-padding"
                          [ngClass]="globalOrderStatus === 'completed'? 'secondary-hover text-under-line' : 'primary-hover'"
                          (click)="filter('completed')">Completed
                  </button>

                  <button class="mr-1 btn btn-status-padding"
                          [ngClass]="globalOrderStatus === 'cancelled'? 'btn-danger text-under-line'
                          : 'bg-light-danger'"
                          (click)="filter('cancelled')">Cancelled
                  </button>

                  <button class="mr-1 btn-status-padding btn"
                          [ngClass]="globalOrderStatus === 'refund'?
                          'yellow-dark text-under-line' : 'yellow-light'"
                          (click)="filter('refund')">Refund
                  </button>

                  <button class="mr-1 btn-status-padding btn"
                          [ngClass]="changeColor?
                          'btn-warning text-under-line' : 'bg-light-warning'"
                          (click)="fetchOrders_Issues()">Issues
                  </button>
                </div>
              </div>
            </div>

            <!--          (click)="moveToShipped()"-->
            <div class="card">
              <div class="card-header">
                <div class="title text-center">
                  <span class="text-uppercase card-title heading-width"
                        *ngIf="rows.length > 0 && globalOrderStatus === 'shipped'">{{ globalOrderStatus === 'shipped' ? 'Shipment' : globalOrderStatus }}</span>
                  <span class="text-uppercase card-title heading-width"
                        *ngIf="rows.length > 0 && globalOrderStatus === 'paymentProcess'">{{ globalOrderStatus === 'paymentProcess' ? 'Payment Process' : globalOrderStatus }}</span>
                  <span class="text-uppercase card-title heading-width"
                        *ngIf="rows.length > 0 && globalOrderStatus === 'pendingShipment'">{{ globalOrderStatus === 'pendingShipment' ? 'Pending Shipment' : globalOrderStatus }}</span><span
                  class="text-uppercase card-title heading-width"
                  *ngIf="rows.length > 0 && globalOrderStatus === 'partialShipment'">{{ globalOrderStatus === 'partialShipment' ? 'Partial Shipment' : globalOrderStatus }}</span>
                  <span class="text-uppercase card-title heading-width"
                        *ngIf="rows.length > 0 && globalOrderStatus !== 'paymentProcess' && globalOrderStatus !== 'shipped' && globalOrderStatus !== 'pendingShipment'&& globalOrderStatus !== 'partialShipment'">{{ globalOrderStatus }}</span>
                </div>
                <h3 class="card-title text-center" *ngIf="rows.length === 0">{{ message }}</h3>
              </div>
              <div class="card-content px-1 py-1">
                <button class="badge navy-dark btn mr-1 float-right"
                        *ngIf="this.hiddeShipBtn && globalOrderStatus === 'dispense'"
                        (click)="openDispenseModal(dispensemodal)"><i class="ft-truck mr-1"></i>Dispensed
                </button>

                <button class="badge primary-color btn mr-1 float-right"
                        (click)="downloadOrdersList()"
                ><i class="ft-download mr-1"></i>Download
                </button>
                <!--                <button class="btn float-right mr-1 btn-sm"-->
                <!--                        ngbTooltip="All partially shipped orders are listed here" placement="top"-->
                <!--                        [ngClass]="globalOrderStatus === 'partialShipment' ? 'partial-shipment-selected' : 'partial-shipment'"-->
                <!--                        (click)="filter('partialShipment')">Partial Shipment-->
                <!--                </button>-->

                <div *ngIf="globalOrderStatus === 'issues'" class="dropdown btn-group  dropup mr-1 mb-1 float-right"
                     ngbDropdown>
                  <button type="button" class="btn badge primary-btn">{{ selectedCategory }}</button>
                  <button type="button" class="btn badge primary-btn dropdown-toggle" ngbDropdownToggle
                          aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a class="dropdown-item" (click)="this.selectedCategory = 'All'; fetchOrders_Issues()">All</a>
                    <a class="dropdown-item" *ngFor="let category of issuesCatgory"
                       (click)="OnFilterIssues(category)">{{ category.categoryName }}</a>
                  </div>
                </div>
                <div *ngIf="globalOrderStatus === 'pendingShipment' || globalOrderStatus === 'partialShipment'"
                     class="dropdown btn-group  dropup mr-1 mb-1 float-right"
                     ngbDropdown>
                  <button type="button" class="btn badge primary-btn">{{ selectedCategory }}</button>
                  <button type="button" class="btn badge primary-btn dropdown-toggle" ngbDropdownToggle
                          aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a class="dropdown-item" (click)="this.selectedCategory = 'All';filter('pendingShipment');">All</a>
                    <a class="dropdown-item"
                       (click)="this.selectedCategory = 'Partially Shipped'; filter('partialShipment')">Partially
                      Shipped</a>
                    <a class="dropdown-item"
                       (click)="this.selectedCategory = 'Pick Up'; filterPendingShipment('pickup')">Pick Up</a>
                  </div>
                </div>
                <button *ngIf="globalOrderStatus === 'issues'"
                        (click)="openIssueCategoryModal(issueCategory_Modal,'noOrderData')"
                        class="badge secondary-btn btn float-right mr-1"><i class="ft-plus mr-1"></i>Add Category
                </button>
                <div class="card-body table-responsive">
                  <table class="table text-center m-0 mt-1">
                    <thead>
                    <tr>
                      <th *ngIf="globalOrderStatus === 'dispense';else others">
                        <div class="checkbox mt-1">
                          <input type="checkbox" [checked]="downloadAllCheck" [disabled]="this.rows.length === 0"
                                 (change)="downloadAll($event)" id="downloadAll">
                          <label for="downloadAll" class="font-weight-bold">Order No</label>
                        </div>
                      </th>
                      <ng-template #others>
                        <th>Order No</th>
                      </ng-template>
                      <th>Customer</th>
                      <th>Date and Time</th>
                      <th>User Status</th>
                      <th>Phone Number</th>
                      <th>Order Status</th>
                      <th *ngIf="globalOrderStatus === 'issues'">Issues Category</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let order of rows">
                      <td>
                        <div class="checkbox"
                             *ngIf="order.status === 'dispense';else othersTemlate">
                          <input type="checkbox" (change)="pushMe(order,$event)" id="{{order._id}}"
                                 [checked]="order.checked"
                          >
                          <label for="{{order._id}}">{{ order.orderNo }}</label>
                        </div>
                        <ng-template #othersTemlate>
                          {{ order.orderNo }}
                        </ng-template>
                      </td>
                      <td>{{ order.name }}</td>
                      <td>
                        {{ order?.orderDate  | date: 'mediumDate' }} {{ order?.orderDate  | date: 'shortTime' }}
                      </td>
                      <td>
                        <span class="badge btn mx-1 text-capitalize" [ngClass]="{'bg-light-success btn mx-1' : order.userId.pharmacyUserStatus === 'verified',
                            'bg-light-secondary btn mx-1' : order.userId.pharmacyUserStatus === 'blackListed',
                            'bg-light-danger btn mx-1': order.userId.pharmacyUserStatus === 'unverified'}">
                              {{ order.userId.pharmacyUserStatus }}</span></td>
                      <td>{{ order.mobile }}</td>

                      <td
                        *ngIf="order.partialShipmentStatus !== 'shipped' && order.partialShipmentStatus !== 'completed' && order.partialShipmentStatus !== 'paymentPending' && order.partialShipmentStatus !== 'paymentCompleted'"
                        class="text-capitalize">{{ order.status === 'paymentProcess' ? 'Payment Process' : order.status !== 'pendingShipment' ? order.status : order.parentOrder ? 'Partially Shipped' : 'pending Shipment' }}
                      </td>

                      <td
                        *ngIf="order.partialShipmentStatus === 'shipped' || order.partialShipmentStatus === 'completed' || order.partialShipmentStatus === 'paymentPending' || order.partialShipmentStatus === 'paymentCompleted'"
                        class="text-capitalize">{{ order.partialShipmentStatus }}
                      </td>

                      <td class="font-weight-bold"
                          *ngIf="globalOrderStatus === 'issues'">{{ order?.issueReportedId === null ? '' : order?.issueReportedId?.categoryName }}
                      </td>
                      <td class="text-left">
                        <div class="d-flex">
                          <button class="badge secondary-btn btn" (click)="ViewOrder(order,viewOrder)">View</button>

                          <button class="badge partial-shipment btn ml-1"
                                  *ngIf="order.status === 'pendingShipment' && order.partialShipmentStatus !== 'completed' && !order.pickup && !order.partialShipment && order.showPartialShipBtn"
                                  (click)="ViewOrder(order,partialShippedModal,'partialShipment')">Partial Shipment
                          </button>

                          <button class="badge navy-dark text-white btn ml-1" *ngIf="order.status === 'dispense'"
                                  (click)="openDispneseModal1(dispensemodal,order);">Dispense
                          </button>

                          <button class="badge bg-light-info btn ml-1"
                                  (click)="confirmation_modal(confirmationModal_Approval,'Please confirm that you would like to Accept this Order?',order)"
                                  *ngIf="order.status === 'new' && !order.issueReported"
                          >Accept
                          </button>

                          <button class="badge primary-btn btn ml-1"
                                  (click)="openReceiptModal(recepiptModal,order,'paymentModal')"
                                  *ngIf="order.status === 'paymentProcess' &&  pharmacy?.manualPaymentType && order?.orderPaymentDetails.length === 0;"
                                  placement="top" ngbTooltip="Update Payment Status">Payment
                          </button>
                          <button class="badge navy-dark text-white btn ml-1"
                                  (click)="this.selectedOrder = order;this.acceptOrder('accepted');"
                                  *ngIf="order.status === 'paymentProcess' && order?.orderPaymentDetails.length > 0 && order?.orderPaymentDetails.length === order.numberOfTimePaymentAccepted ||
                                  (order.status === 'paymentProcess' && order.partialShipmentStatus === 'paymentCompleted')"
                          > Move to Dispense
                          </button>

                          <button
                            *ngIf="(order.userId.pharmacyUserStatus === 'unverified' && order.status === 'new' && !order.userId.user_address_verified) ||
(order.userId.pharmacyUserStatus === 'verified' && order.status === 'new' && !order.userId.user_address_verified) ||
(order.userId.pharmacyUserStatus === 'unverified' && order.status === 'new' && !order.userId.user_address_verified) ||
(order.userId.pharmacyUserStatus === 'unverified' && order.status === 'new' && order.userId.user_address_verified) || order.userId.pharmacyUserStatus === 'pending'
"
                            (click)="openModal(verifyModal,order)"
                            class="badge bg-light-success btn ml-1"><i class="ft-check-circle"></i></button>

                          <button class="btn badge badge-info ml-1"
                                  *ngIf="order.status === 'pendingShipment' &&
                                  ( order.status !== 'paymentProcess' && order.partialShipmentStatus === 'paymentCompleted') && !order.pickup && !order.parentOrder && order.partialShipmentStatus !== 'shipped' &&
                                  order.partialShipmentStatus === 'pending' && (order.partialShipmentStatus !== 'completed' || order.partialShipmentStatus === 'paymentCompleted')"
                                  (click)="openReceiptModal1(recepiptModal1,order,'lg')"><i
                            class="ft-truck mr-1"></i>Shipped
                          </button>
                          <button class="btn badge badge-info ml-1"
                                  *ngIf="order.status === 'pendingShipment' && !order.pickup && !order.parentOrder && order.partialShipmentStatus !== 'shipped' &&
                                  order.partialShipmentStatus === 'pending' && (order.partialShipmentStatus !== 'completed' || order.partialShipmentStatus === 'paymentCompleted')"
                                  (click)="openReceiptModal1(recepiptModal1,order,'lg')"><i
                            class="ft-truck mr-1"></i>Shipped
                          </button>
                          <!--                        <ng-container *ngIf="order.pickup && order.status === 'pickup'">-->
                          <!--                          <button class="btn badge primary-color ml-1"-->
                          <!--                                  (click)="update('completed',order)"><i class="ft-check-circle mr-1"></i>Completed-->
                          <!--                          </button>-->
                          <!--                        </ng-container>-->
                          <!--                        (click)="moveToCancel(order)"-->

                          <!--                          (click)="updateOrderToComplete(order)"-->
                          <button class="btn badge primary-color ml-1"
                                  *ngIf="(order.status === 'shipped' && !order.pickup) || (order.partialShipmentStatus === 'shipped')"
                                  (click)="confirmation_modal(confirmationModal_Approval,'Please confirm that you would like to Complete this Order?',order)">
                            <i class="ft-check-circle mr-1"></i>Completed
                          </button>
                          <button class="badge btn primary-color ml-2"
                                  *ngIf="order.status === 'refund'"
                                  (click)="confirmation_modal(confirmationModal_Approval,'Are you certain you wish to proceed with a refund?',order)"
                          >Refund Complete <i class="ft-check-circle mr-1"></i>
                          </button>
                          <button class="badge btn primary-color ml-2"
                                  *ngIf="order.status === 'pending'"
                                  (click)="confirmation_modal(confirmationModal_Approval,'Please confirm that you would like to Approve this Order?',order)"
                          >Approve
                          </button>
                          <a class="badge bg-light-danger btn ml-1" *ngIf="order.status === 'dispense'"
                             (click)="openConfirmModal(confirmModal,order)">Reject
                          </a>

                          <a class="badge bg-light-danger btn ml-1"
                             *ngIf="order.status === 'pending' || order.status === 'paymentProcess' "
                             (click)="openConfirmModal(cancelOrderModal,order)">Reject
                          </a>
                          <button class="badge badge-primary btn ml-1"
                                  *ngIf="order.status === 'pendingShipment' && order.pickup"
                                  (click)="openReceiptModal1(pickUpModal,order,'md')">
                            <i class="ft-shopping-bag"></i>
                            Pick Up
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
                <div class="card-footer">
                  <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize * 10"
                                  (pageChange)="pagination($event)"
                                  [(page)]="page1" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #pickUpModal let-modal>
  <!--  (click)="update('pickup',order)"-->
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <h4 class="modal-title">This Order will be marked as Completed</h4>
    <div class="row">
      <div class="col-12 px-3 py-3 text-center">
        <button class="btn secondary-btn" (click)="MoveToComplete('pickup',modal)">Continue</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #recepiptModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Receipt</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="receiptAcc" (ngSubmit)="OnSubmitReceipt(modal)">
      <div class="row">
        <div class="col-md-12 col-12">
          <label>Order Amount <small class="text-danger">*</small></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="ft-dollar-sign"></i></span>
            </div>
            <input type="text" (keyup)="validation = false;disablebtn = false;" class="form-control square"
                   formControlName="orderAmount"
                   [readonly]="enableReadOnly">
            <div class="input-group-prepend">
              <span class="input-group-text cursor-pointer" (click)="enableReadOnly = !enableReadOnly;"><i
                class="ft-edit"></i></span>
            </div>
          </div>
          <p *ngIf="submitted && receiptAcc.controls['orderAmount'].invalid" class="text-danger">Order Amount
            is required</p>
          <p *ngIf="receiptAcc.controls['orderAmount'].hasError('pattern')" class="text-danger">Order Amount
            is Invalid</p>
          <p *ngIf="validation" class="text-danger">The entered amount is less than the total amount</p>
        </div>
        <div class="col-md-12 col-12">
          <label>Payment Method <small class="text-danger">*</small></label>
          <input type="text" class="form-control square" formControlName="orderPayment_method">
          <p *ngIf="submitted && receiptAcc.controls['orderPayment_method'].invalid" class="text-danger">Payment Method
            is required</p>
        </div>
        <div class="col-md-12 col-12">
          <label>Transaction Number <small class="text-danger">*</small></label>
          <input type="text" class="form-control square" formControlName="orderPayment_reference">
          <p *ngIf="submitted && receiptAcc.controls['orderPayment_reference'].invalid" class="text-danger">Transaction
            Number is required</p>
          <p class="text-danger" *ngIf="receiptAcc.controls['orderPayment_reference'].hasError('pattern')">
            Invalid Transaction Number
          </p>
        </div>
      </div>
      <!--      <div class="row">-->
      <!--        <div class="col-md-6 col-12">-->
      <!--          <label>Shipped Date</label>-->
      <!--          <div class="input-group calendar-width">-->
      <!--            <input class="form-control square" (dateSelect)="addShippedDate($event)"-->
      <!--                   [minDate]="today"-->
      <!--                   [(ngModel)]="model" [ngModelOptions]="{standalone: true}" name="dp" ngbDatepicker-->
      <!--                   #d2="ngbDatepicker">-->
      <!--            <div class="input-group-append">-->
      <!--              <div class="input-group-text" (click)="d2.toggle()">-->
      <!--                <i class="fa fa-calendar" style="cursor: pointer;"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="row">
        <div class="col-12 px-1 py-2 text-center">
          <button class="btn btn-success btn ml-1" type="submit" [disabled]="disablebtn"><i
            class="ft-check-circle mr-1"></i>Proceed
          </button>
          <a class="btn btn-danger ml-1" (click)="openConfirmModal(cancelOrderModal,this.selectedOrder)"
             *ngIf="selectedOrder.status === 'paymentProcess'"><i
            class="ft-x mr-1"></i>Reject
          </a>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #recepiptModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Shipping Details</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="carrierForm" (ngSubmit)="Complete_carrier(modal)">
      <div class="row">
        <div class="col-6">
          <label>Carrier Number</label>
          <input type="text" class="form-control square" formControlName="carrier_number">
          <p *ngIf="submitted && carrierForm.controls['carrier_number'].invalid" class="text-danger">Carrier Number
            is required</p>
        </div>
        <div class="col-6">
          <label>Connote Number</label>
          <input type="text" class="form-control square" formControlName="connate_num">
          <p *ngIf="submitted && carrierForm.controls['connate_num'].invalid" class="text-danger">Connote number is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-1 py-2 text-center">
          <button class="btn secondary-btn btn ml-1" type="submit"><i class="ft-check-square mr-2"></i>Complete</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #verifyModal let-modal class="max-modal">
  <div class="modal-header">
    <h4 class="modal-title">Update customer status</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4">
        <label class="font-weight-bold">Name</label>
        <p>{{ this.selectedCustomer?.firstName }} {{ this.selectedCustomer?.middleName === null || this.selectedCustomer?.middleName === '' ? ' ' : this.selectedCustomer?.middleName }} {{ this.selectedCustomer?.lastName }}</p>
      </div>
      <div class="col-4">
        <label class="font-weight-bold">Email</label>
        <p>{{ this.selectedCustomer.email }}</p>
      </div>
      <div class="col-4">
        <label class="font-weight-bold">Mobile Number</label>
        <p>{{ this.selectedCustomer?.number }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <label class="font-weight-bold">Address</label>
        <p>{{ this.selectedOrder.address + ' ' + this.selectedOrder.suburb + ' ' + this.selectedOrder.state + ' ' + this.selectedOrder.postcode }}</p>
      </div>
      <div class="col-4">
        <label class="font-weight-bold">Date Of Birth</label>
        <p>{{ this.dateOfBirth }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12" *ngIf="this.pharmacyUser?.licenseFront_temp_encrypted !== 'IA=='">
        <label class="font-weight-bold">Front License
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Front License"
            (click)="downloadScript(this.licenseFront)"></i>
        </label><br>
        <img *ngIf="this.pharmacyUser?.licenseFront_temp_encrypted !== 'IA=='" [src]="this.licenseFront" alt=""
             width="250" height="180">
      </div>
      <div class="col-md-6 col-12" *ngIf="this.pharmacyUser?.licenseBack_temp_encrypted !== 'IA=='">
        <label class="font-weight-bold">Back License
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Back License"
            (click)="downloadScript(this.licenseBack)"></i>
        </label><br>
        <img *ngIf="this.pharmacyUser?.licenseBack_temp_encrypted !== 'IA=='" [src]="this.licenseBack" alt=""
             width="250"
             height="180" class="ml-2">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12" *ngIf="this.pharmacyUser?.medicareCardNumber !== 'IA=='">
        <label class="font-weight-bold">Medicare Card
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Medicare Card"
            (click)="downloadScript(this.medicareLicense)"></i>
        </label><br>
        <img *ngIf="this.pharmacyUser?.medicareCardNumber !== 'IA=='" [src]="this.medicareLicense" alt="" width="250"
             height="180"
             class="ml-2">
      </div>
      <div class="col-md-6 col-12" *ngIf="this.pharmacyUser?.pensionCardNumber !== 'IA=='">
        <label class="font-weight-bold">Pension Card
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Pension Card"
            (click)="downloadScript(this.pensionCardNumber)"></i>
        </label><br>
        <img *ngIf="this.pharmacyUser?.pensionCardNumber !== 'IA=='" [src]="this.pensionCardNumber" alt="" width="250"
             height="180"
             class="ml-2">
      </div>
    </div>


    <div class="d-flex align-items-center justify-content-center px-2 py-2">
      <button type="button" class="btn btn-outline-success"
              (click)="confirmation_modal(confirmationModal_Approval,'Please confirm that you would like to Approve this Customer?',this.selectedOrder)">{{ btnTitle }}
      </button>
      <button type="button" class="btn btn-outline-dark ml-1" (click)="verifyCustomer('blackListed',modal)"
              *ngIf="btns">
        Blacklist
      </button>
      <button type="button" class="btn btn-outline-danger ml-1" *ngIf="btns"
              (click)="openPendModal(openPenModal)">
        Pending
      </button>
    </div>
  </div>
</ng-template>


<ng-template #openPenModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Send Verification Note</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="card overflow-hidden">
      <div class="card-content px-2 py-2">
        <textarea #text cols="30" rows="4" class="form-control square"></textarea>
        <div class="card-body text-center">
          <button type="button" class="btn btn-warning" (click)="sendMessage(text.value,modal)"><i
            class="ft-send mr-2"></i>Send
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #editProducts let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Update Product</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="editProd" (ngSubmit)="updateProduct(modal)">
      <div class="row">
        <div class="col-6">
          <label>Select Product</label>
          <ng-select [items]="productList" [virtualScroll]="true"
                     formControlName="product_name"
                     (change)="updateProdDetails($event,modal)" bindLabel="productName" bindValue="productName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p>{{ item.productName }}
                <span *ngIf=" item.stock <= 0"
                      class="badge bg-light-warning btn text-capitalize float-right">Backorder</span>
              </p>
            </ng-template>
          </ng-select>
          <!--            <p class="text-danger" *ngIf="editProd.controls['product_name'].hasError('required')">ProductName is-->
          <!--              required</p>-->
        </div>
        <div class="col-6">
          <label>Price</label>
          <input type="text" class="form-control square" formControlName="price">
          <p class="text-danger" *ngIf="editProd.controls['price'].hasError('pattern')">Invalid price</p>
          <p class="text-danger" *ngIf="editProd.controls['price'].hasError('required')">Price is required</p>
        </div>
        <div class="col-6">
          <label>Note <i class="ft-info text-info" placement="top"
                         ngbTooltip="Medicine Description"></i></label>
          <textarea class="form-control square" cols="10" rows="2" formControlName="note"></textarea>
        </div>
        <div class="col-6">
          <label>Instruction <i class="ft-info text-info" placement="top"
                                ngbTooltip="Customer Medication"></i></label>
          <textarea class="form-control square" cols="10" rows="2" formControlName="instruction"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center px-1 py-2">
          <button class="btn primary-color"
                  [disabled]="editProd.controls['price'].hasError('pattern')"
                  type="submit">Save
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #ScriptOpen let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Search Medicine</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>Select Product</label>
        <ng-select [items]="productList"
                   (change)="onChangeProduct($event,modal)" bindLabel="productName" bindValue="productName">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <p>{{ item.productName }}
              <span *ngIf="item.stock <= 0 && !item?.isDiscontinued" class="badge  btn text-capitalize float-right"
                    [ngClass]="this.selectedPharmacy?.acceptBackOrders ? 'bg-light-warning' : 'bg-light-danger'">
                {{ this.selectedPharmacy?.acceptBackOrders ? 'Backorder' : 'Out of stock' }}</span>

              <span *ngIf="item.stock > 0 && !item?.isDiscontinued"
                    class="badge bg-light-success btn text-capitalize float-right">In-Stock</span>

              <span *ngIf="item?.isDiscontinued"
                    class="badge bg-light-secondary btn text-capitalize float-right">Discontinued</span>
            </p>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #viewOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Order - #{{ selectedOrder.orderNo }}</h4>

    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="closeRestModal(modal)"></span>
  </div>
  <div class="modal-dialog-scrollable">
    <div class="modal-body">
      <button *ngIf="!this.selectedOrder?.issueReported && this.selectedOrder?.status !== 'cancelled'"
              (click)="openIssueCategoryModal(issueCategory_Modal)"
              class="badge bg-light-warning btn float-right"><i class="ft-alert-triangle mr-1"></i>Report Issue
      </button>
      <button *ngIf="this.selectedOrder?.status === 'paymentProcess' && !selectedPharmacy?.manualPaymentType"
              (click)="confirmResendMail(confirmMail)"
              class="badge bg-info btn float-right text-white mr-1"><i class="ft-credit-card mr-1"></i>Resend payment
        link
      </button>
      <button class="badge partial-shipment btn float-right mr-1"
              *ngIf="selectedOrder.status === 'pendingShipment' && selectedOrder.partialShipmentStatus !== 'completed' && !selectedOrder.pickup && !selectedOrder.partialShipment && selectedOrder.showPartialShipBtn"
              (click)="ViewOrder(selectedOrder,partialShippedModal,'InsideViewModal')">Partial Shipment
      </button>
      <button class="btn badge badge-info float-right mr-1"
              *ngIf="selectedOrder.status === 'pendingShipment' && (selectedOrder.status !== 'paymentProcess' && selectedOrder.partialShipmentStatus === 'paymentCompleted') && !selectedOrder.pickup && !selectedOrder.parentOrder && selectedOrder.partialShipmentStatus !== 'shipped' &&
                                  selectedOrder.partialShipmentStatus === 'pending' && (selectedOrder.partialShipmentStatus !== 'completed' || selectedOrder.partialShipmentStatus === 'paymentCompleted')"
              (click)="openReceiptModal1(recepiptModal1,this.selectedOrder,'lg','InsideViewModal')"><i
        class="ft-truck mr-1"></i>Shipped
      </button>

      <button class="btn badge badge-info float-right mr-1"
              *ngIf="selectedOrder.status === 'pendingShipment' && !selectedOrder.pickup && !selectedOrder.parentOrder && selectedOrder.partialShipmentStatus !== 'shipped' &&
                                  selectedOrder.partialShipmentStatus === 'pending' && (selectedOrder.partialShipmentStatus !== 'completed' || selectedOrder.partialShipmentStatus === 'paymentCompleted')"
              (click)="openReceiptModal1(recepiptModal1,this.selectedOrder,'lg')"><i
        class="ft-truck mr-1"></i>Shipped
      </button>

      <button (click)="reportIssue('false','Issue Resolved Successfully')"
              *ngIf="this.selectedOrder?.issueReported" class="badge bg-light-info btn float-right mr-1"><i
        class="ft-settings mr-1"></i>Resolve Issue
      </button>
      <h5 class="font-weight-bold font-italic">User Information</h5>
      <hr>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-9 col-12">
          <div style="overflow-y: scroll !important;overflow-x: hidden !important;">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-8 col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                    <label class="font-weight-bold">Name</label>
                    <p>{{ selectedOrder?.name }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Mobile Number</label>
                    <p>{{ selectedOrder?.mobile }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Email</label>
                    <p style="word-break: break-word">{{ selectedOrder?.email }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Order By</label><br>
                    <p>{{ selectedOrder?.orderedBy }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Ordered Date and Time</label><br>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                       *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                    <label class="font-weight-bold">Cancellation Reason</label><br>
                    <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                    <p
                      *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
                  </div>

                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Delivery Type</label><br>
                    <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                    <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Status</label><br>
                    <span
                      class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : pharmacyUser?.pharmacyUserStatus === 'verified','bg-light-dark btn' : pharmacyUser?.pharmacyUserStatus === 'blacklisted',
                            'bg-light-danger btn': pharmacyUser?.pharmacyUserStatus === 'unverified'}">
                              {{ pharmacyUser?.pharmacyUserStatus }}</span>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                    <label class="font-weight-bold">Order Status</label><br>
                    <span
                      *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                      class="text-capitalize">{{ selectedOrder.status }}</span>
                    <span
                      *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                      class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                    <label class="font-weight-bold">Address</label>
                    <p>
                      <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                    </p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                       *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                    <label class="font-weight-bold">Pickup Name</label><br>
                    <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                       *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                    <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                      <i
                        *ngIf="showUrl"
                        class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                        (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                    </label>
                    <br>
                    <div *ngIf="showUrl">
                      <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                           height="40"
                           (click)="isCollapsed = !isCollapsed"
                           [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                      <div id="collapseExample" [ngbCollapse]="isCollapsed">
                        <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                      </div>
                    </div>
                    <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-xl-4 col-12">
                <div class="chat-container d-none d-sm-none d-xl-block d-md-none d-lg-none">
                  <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand"
                                                                                   [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'"
                                                                                   class="cursor-pointer float-right"></i>
                  </div>
                  <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                    <div class="messages">
                      <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                        <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                           *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                           (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                        {{ note.fallowUp_note }}
                        <p
                          class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                          {{ note.note_addedBy_userName }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="input-area">
                    <!--                  <input type="text"  id="message-input" #messageBox placeholder="Type a note..." >-->
                    <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox)" #messageBox
                              placeholder="Type a note..."></textarea>
                    <button class="btn btn-success ml-2" style="height: 40px;"
                            (click)="onEnterKey(messageBox)">
                      <i class="ft-send mr-1"></i>
                    </button>
                  </div>
                </div>
                <div class="d-xl-none">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                      <div class="chat-container">
                        <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand"
                                                                                         [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'"
                                                                                         class="cursor-pointer float-right"></i>
                        </div>
                        <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                          <div class="messages">
                            <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                              <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                                 *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                                 (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                              {{ note.fallowUp_note }}
                              <p
                                class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                                {{ note.note_addedBy_userName }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="input-area">
                          <!--                  <input type="text"  id="message-input" #messageBox placeholder="Type a note..." >-->
                          <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox2)" #messageBox2
                                    placeholder="Type a note..."></textarea>
                          <button class="btn btn-success ml-2" style="height: 40px;"
                                  (click)="onEnterKey(messageBox2)">
                            <i class="ft-send mr-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                      <div class="pharmacy-chat-container">
                        <div class="chat-header bg-secondary">Pharmacy Notes</div>
                        <div class="chat-box">
                          <div class="messages">
                            <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                              <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                                 *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                                 (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                              {{ note.fallowUp_note }}
                              <p
                                class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                                {{ note.note_addedBy_userName }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="input-area">
            <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox1,'pharmacyNote')" #messageBox1
                      placeholder="Type a note..."></textarea>
                          <button class="btn bg-success text-white ml-2" style="height: 40px;"
                                  (click)="onEnterKey(messageBox1,'pharmacyNote')">
                            <i class="ft-send mr-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="d-flex">
              <h5 class="font-weight-bold font-italic">User Script</h5>
              <button class="badge badge-warning btn ml-2 mb-1" *ngIf="selectedOrder.status === 'dispense'"
                      (click)="openDispneseModal1(dispensemodal,this.selectedOrder)">Dispense
              </button>
            </div>
            <div class="table-responsive">
              <table class="table items-table">
                <thead>
                <tr>
                  <!--          <th class="text-left">QR Code</th>-->
                  <th>Token Number</th>
                  <th>Script Info</th>
                  <th>Brand Name</th>
                  <th>Product Name</th>
                  <th>Repeats</th>
                  <th>Quantity</th>
                  <th
                    *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">
                    Partial Shipped Quantity
                  </th>
                  <th
                    *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">
                    Remaining Quantity
                  </th>
                  <th>Price</th>
                  <th>Instruction</th>
                  <th>Note</th>
                  <ng-container *ngIf="selectedOrder.status === 'new'">
                    <th>Actions</th>
                  </ng-container>
                  <th>Backorder</th>

                </tr>
                </thead>
                <tr *ngFor="let item of this.orderedItems;let i = index;">
                  <!--          <td class="py-0">-->
                  <!--            <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
                  <!--          </td>-->
                  <td> <span (click)="copyToken(item?.token)" class="text-UpperCase">
                        <i class="cursor-pointer ft-copy mr-1 " ngbTooltip="Token copied" triggers="click:blur"></i>
                      </span>{{ item?.token }}
                  </td>
                  <td class="text-center">
                    <div class="d-flex">
                      <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                           class="cursor-pointer"
                           src="{{ item?.scriptData?.uploadedScript }}"
                           alt="" width="60" height="40"
                           (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
                      <span ngbTooltip="Download ScriptImage"
                            *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                            class="ft-download-cloud cursor-pointer ml-2"
                            (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
                    </div>
                    <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
                       href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
                    </a>
                  </td>
                  <td>{{ item?.product_brand_name }}</td>
                  <td class=" cursor-pointer"
                      (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
                  </td>
                  <td>{{ item?.repeats }}</td>
                  <td>{{ item?.quantity }}</td>
                  <td
                    *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.shippedQuantity }}
                  </td>
                  <td
                    *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.remainingQuantity }}
                  </td>
                  <td>${{ item?.price }}</td>
                  <td>{{ item?.instruction }}</td>
                  <td>{{ item?.note }}</td>

                  <ng-container
                    *ngIf="selectedOrder.status === 'new'">
                    <td>
              <span class="cursor-pointer" (click)="open(editProducts,item,i)"><i
                class="ft-edit text-primary"></i></span>
                      <!--              <span class="cursor-pointer ml-1" (click)="removeItem(item)"><i class="ft-trash"></i></span>-->
                    </td>
                  </ng-container>
                  <td *ngIf="item.isBackOrder" class="text-center">
                 <span
                   class="badge bg-light-warning btn text-capitalize">Backorder</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right"><b>Item Total</b></td>
                  <td>${{ selectedOrder?.itemsCost | number:'1.2-2' }}</td>
                </tr>
                <tr *ngIf="!selectedOrder?.pickup">
                  <td colspan="5" class="text-right"><b>Delivery Charge:</b>
                    <p *ngIf="selectedOrder.status === 'new'" class="pt-1">
                      <input type="number" #updatedDeliveryCharge [disabled]="enable" min="0"
                             [value]="selectedOrder.freightCharge">
                      <span class="cursor-pointer ml-2 ft-edit" *ngIf="enable"
                            (click)="this.enable = !this.enable;"></span>
                      <span class="cursor-pointer ml-2 ft-check-circle text-success" *ngIf="!enable"
                            (click)="updateFreightCharge(updatedDeliveryCharge.value)"></span>
                    </p>
                  </td>
                  <td>
                    ${{ selectedOrder.freightCharge }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right"><b>Total Amount:</b></td>
                  <td><p class="font-weight-bold font-medium-1">${{ selectedOrder?.totalAmount | number:'1.2-2' }}<br>
                    <span
                      class="font-weight-normal font-small-3">*Includes GST of ${{ selectedOrder.taxAmount | number:'1.2-2' }}</span>
                  </p></td>
                </tr>
                <tbody>
                </tbody>
              </table>
            </div>
            <br>
            <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
            <hr>
            <div class="row">
              <div class="col-md-4 col-sm-12 col-lg-4 col-12">
                <div class="col-12">
                  <label class="font-weight-bold">Pharmacy Name</label>
                  <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
                </div>
                <div class="col-12 mt-2">
                  <label class="font-weight-bold">Mobile Number</label>
                  <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-lg-4 col-12">
                <div class="col-12">
                  <label class="font-weight-bold">Email</label>
                  <p>{{ selectedOrder?.pharmacyId?.email }}</p>
                </div>
                <div class="col-12">
                  <label class="font-weight-bold">Pharmacy Address</label>
                  <p>{{
                      selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                      ' ' + selectedOrder?.pharmacyId?.postcode
                    }}</p>
                </div>
              </div>
            </div>
            <!--Payment Details-->
            <br>
            <h5 class="font-weight-bold font-italic">Payment Details
              <button class="badge bg-light-primary btn ml-1" (click)="openExtraChargeModal(extraChargeModal)"
                      *ngIf="selectedOrder.status !== 'completed' && selectedOrder.status !== 'cancelled' && selectedOrder.status !== 'refund'">
                <i
                  class="ft-credit-card mr-1"></i>Additional Payments
              </button>
            </h5>
            <div
              *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
              <hr>
              <div class="table-responsive" *ngIf="selectedOrder?.orderPaymentDetails.length > 0">
                <table class="table table-bordered m-0">
                  <thead class="table-header">
                  <tr>
                    <th>Order No</th>
                    <th>Amount</th>
                    <th>Order Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Number</th>
                    <th>Date & Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                    <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                    <td>$ {{ payment?.orderPayment_Amount }}</td>
                    <td>
                      <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                    </td>
                    <td>{{ payment?.orderPayment_method === 'Internet' ? 'Online Payment' : payment?.orderPayment_method }}</td>
                    <td>{{ payment?.orderPayment_reference }}</td>
                    <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--      Additional Cost Details-->
            <br>
            <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional
              Payments
              <button
                *ngIf="pharmacy?.manualPaymentType && selectedOrder.status !== 'new' && selectedOrder.status !== 'pending' && selectedOrder.status !== 'completed' && selectedOrder.status !== 'cancelled' && selectedOrder.status !== 'refund'"
                [disabled]="!disablOnlinePayment"
                class="ml-1 btn badge-info badge font-italic font-weight-bold cursor-pointer"
                (click)="openReceiptModal(recepiptModal,this.selectedOrder)">Payment
              </button>
              <button
                *ngIf="!pharmacy?.manualPaymentType && selectedOrder.status !== 'new' && selectedOrder.status !== 'pending' && selectedOrder.status !== 'completed' && selectedOrder.status !== 'cancelled' && selectedOrder.status !== 'refund'"
                [disabled]="!disablOnlinePayment"
                class="ml-1 btn badge-info badge font-italic font-weight-bold cursor-pointer"
                (click)="initiateConfirm(initiatePaymentConfirm)"
              >Payment
              </button>
            </h5>
            <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
              <div class="col-md-12 col-12">
                <div class="table-responsive">
                  <table class="table table-bordered m-0">
                    <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Payment Status</th>
                      <th>Amount Added By</th>
                      <!--                      <th *ngIf="pharmacy?.manualPaymentType">Actions</th>-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                      <td>$ {{ extracost.amount }}</td>
                      <td>{{ extracost.description }}</td>
                      <td>
                        <span [ngClass]="extracost.paid ? 'bg-light-success' : 'bg-light-secondary'"
                              class="badge ">{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</span>
                      </td>
                      <td>{{ extracost.amount_addedBy_userName }}</td>
                      <!--                      <td *ngIf="pharmacy?.manualPaymentType">-->
                      <!--                        <button-->
                      <!--                          *ngIf="!extracost.paid && pharmacy?.manualPaymentType && selectedOrder.status !== 'new' && selectedOrder.status !== 'pending'"-->
                      <!--                          class="ml-1 btn badge-info badge font-italic font-weight-bold cursor-pointer"-->
                      <!--                          (click)="openReceiptModal(recepiptModal,this.selectedOrder,'extraChargeModal',extracost.amount,i)">-->
                      <!--                          Payment-->
                      <!--                        </button>-->
                      <!--                      </td>-->
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--      Delivery Details-->
            <br>
            <div *ngIf="selectedOrder?.shippingDetails.length > 0">
              <h5 class="font-weight-bold font-italic">Delivery Details</h5>
              <hr>
              <div class="table-responsive">
                <table class="table table-bordered m-0">
                  <thead class="table-header">
                  <tr>
                    <th>Order No</th>
                    <th>Order Date</th>
                    <th>Carrier Number</th>
                    <th>Connote Number</th>
                    <th>Delivery Date & Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                    <td class="cursor-pointer"
                        (click)="viewChildOrder(ele?.itemShippedDetails[0]?.orderNumber,productsShipped)">
                      <span style="text-decoration: underline;">{{ ele?.itemShippedDetails[0]?.orderNumber }}</span>
                    </td>
                    <td>
                      <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                    </td>
                    <td>{{ ele?.freightCarrier }}</td>
                    <td>{{ ele?.trackingNo }}</td>
                    <td>{{ ele?.deliveredDate  | date:'mediumDate' }} {{ ele?.deliveredDate  | date:'shortTime' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row" *ngIf="this.selectedOrder.status === 'new'">
              <div class="col-md-12 col-lg-12 col-12 text-center">
                <button class="btn btn-success"
                        (click)="confirmation_modal(confirmationModal_Approval,'Please confirm that you would like to Accept this Order?',selectedOrder)">
                  <i
                    class="ft-check-circle mr-1"></i>Accept
                </button>
                <button class="btn btn-danger ml-1" (click)="openConfirmModal(cancelOrderModal,this.selectedOrder)"><i
                  class="ft-x mr-1"></i>Reject
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-3 col-12">
          <div class="pharmacy-chat-container mx-1 my-1 d-none d-sm-none d-xl-block d-md-none d-lg-none">
            <div class="chat-header bg-secondary">Pharmacy Notes</div>
            <div class="pharmacy-chat-box">
              <div class="messages">
                <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                  <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                     *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                     (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                  {{ note.fallowUp_note }}
                  <p
                    class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                    {{ note.note_addedBy_userName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="input-area">
            <textarea cols="31" rows="1" (keyup.enter)="sendNote(pharmacy_Notes,'pharmacyNote')" #pharmacy_Notes
                      placeholder="Type a note..."></textarea>
              <button class="btn bg-success text-white ml-2" style="height: 40px;" [disabled]="disablebtn"
                      (click)="sendNote(pharmacy_Notes,'pharmacyNote')">
                <i class="ft-send mr-1"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>

<ng-template #initiatePaymentConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Initiate Payment</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body text-center">
    <p>Confirm sending the payment link via email?</p>
    <button class="btn btn-danger" (click)="initPayment('true')">Yes</button>
  </div>
</ng-template>


<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Cancel Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Please provide a reason for rejecting this order</p>
    <div class="row">
      <div class="col-12">
        <label class="font-weight-bold">Reason<sup class="text-danger">*</sup></label>
        <input type="text" class="form-control square" #reasonbox (keyup)="validateToken(reasonbox.value)">
        <p *ngIf="addReson !== ''"
           class="text-danger">{{ addReson }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="font-weight-bold">Refund Amount<sup class="text-danger">*</sup></label>
        <input type="text" class="form-control square" #refundAmount>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 text-center px-2 py-2">
        <button class="btn btn-warning" (click)="refund(reasonbox,refundAmount)">Initiate Refund</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #cancelOrderModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Cancel Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Please provide a reason for rejecting this order</p>
    <div class="row">
      <div class="col-12">
        <label class="font-weight-bold">Reason<sup class="text-danger">*</sup></label>
        <input type="text" class="form-control square" #reasonbox (keyup)="addReson = ''">
        <p *ngIf="submitted && addReson === 'Reason field is required'" class="text-danger">{{ addReson }}</p>
        <p *ngIf="addReson === 'Please ensure there are no spaces at the beginning or end of the text'"
           class="text-danger">{{ addReson }}</p>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 text-center px-2 py-2">
        <button class="btn btn-danger" (click)="cancelOrder(reasonbox)">Yes</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Create Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="close_reset(modal)"></span>
  </div>
  <div class="modal-body">
    <section id="ngx-datatable-filter-1">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step [stepTitle]="'Step 1'" [navigationSymbol]="{ symbol: '&#xf19c;', fontFamily: 'FontAwesome' }">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-sm-12">
              <!-- Filter starts -->
              <div class="users-list-filter px-2">
                <form [formGroup]="searchPhone">
                  <div class="row border rounded py-2 mb-2 mx-n2">
                    <div class="col-12 col-md-6 col-sm-12 col-lg-3">
                      <label for="users-list-verified">Search By</label>
                      <fieldset class="form-group">
                        <select id="users-list-verified" class="form-control" (change)="OnChange($event)">
                          <option value="name">Name</option>
                          <option value="phoneNumber">Phone Number</option>
                          <option value="email">Email</option>
                        </select>
                      </fieldset>
                    </div>

                    <div class="col-12 col-md-6 col-sm-12 col-lg-3">
                      <label></label>
                      <fieldset>
                        <div class="input-group" *ngIf="type === 'phoneNumber'">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">+61</span>
                          </div>
                          <input type="text" class="form-control" formControlName="phone"
                                 placeholder="Enter Phone number"
                                 id="phone"
                                 (keyup.enter)="searchCustomer(this.type)"
                                 maxlength="10" aria-describedby="basic-addon1">
                        </div>
                        <div *ngIf="type === 'email'">
                          <input type="email" id="email" class="form-control" formControlName="email"
                                 placeholder="Enter Email"
                                 (keyup.enter)="searchCustomer(this.type)">
                          <p *ngIf="searchPhone.controls['email'].hasError('email')" class="text-danger">Invalid
                            Email</p>
                        </div>
                        <div *ngIf="type === 'name'">
                          <input type="text" class="form-control" formControlName="name"
                                 placeholder="Enter Name"
                                 (keyup.enter)="searchCustomer(this.type)">
                        </div>
                        <p class="text-danger" *ngIf="searchPhone.controls['name'].hasError('pattern')">Invalid Name</p>
                      </fieldset>
                    </div>
                    <div class="col-12 col-md-6 col-sm-12 col-lg-1">
                      <button class="btn mt-3 primary-color d-none d-sm-none d-lg-block d-md-none"
                              (click)="searchCustomer(this.type)"><i
                        class="ft-search"></i></button>
                      <button class="btn mt-3 primary-color btn-block d-sm-block d-lg-none d-md-block"
                              (click)="searchCustomer(this.type)"><i
                        class="ft-search mr-1"></i></button>
                    </div>
                    <div class="col-12 col-md-6 col-sm-12 col-lg-1">
                      <button class="btn mt-3 secondary-color d-none d-sm-none d-lg-block d-md-none"
                              (click)="openCreateOdrModal(AddCustomer,'xl')"><i
                        class="ft-plus-circle mr-1"></i>Register
                      </button>
                      <button class="btn mt-3 secondary-color btn-block d-sm-block d-lg-none d-md-block"
                              (click)="openCreateOdrModal(AddCustomer,'xl')"><i
                        class="ft-plus-circle mr-1"></i>Register
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Filter ends -->
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-sm-12" *ngIf="this.customerList.length > 0">
              <div class="card">
                <div class="card-body">
                  <div class="d-none d-lg-block d-md-none d-sm-none">
                    <ngx-datatable class="material core-bootstrap" [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50" rowHeight="150px" [rows]="customerList" [limit]="limitRef"
                                   [columns]="columns">
                      <ngx-datatable-column name="Name" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <p>{{ row.firstName }} {{ row.middleName }} {{ row.lastName }}</p>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Phone number" prop="number"
                                            [width]="150"></ngx-datatable-column>
                      <ngx-datatable-column name="Date Of Birth">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <p>{{ row.convertedDob === 'Invalid date' ? '' : row.convertedDob }}</p>
                        </ng-template>
                        [width]="150">
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Address" [width]="320">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div class="d-flex" *ngIf="row.addresses?.length > 0">
                            <ng-select id="{{row._id}}" [items]="row?.addresses" bindLabel="completeAddress"
                                       bindValue="_id" class="size"
                                       (change)="SelectedAddress($event,row)"
                                       name="userAddress">
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                <b>{{ item.completeAddress }}</b>
                              </ng-template>
                            </ng-select>
                          </div>

                        </ng-template>
                      </ngx-datatable-column>
                      <!--                      <ngx-datatable-column name="Actions" [width]="150">-->
                      <!--                        <ng-template let-row="row" ngx-datatable-cell-template>-->
                      <!--                          <button class="btn mr-2"-->
                      <!--                                  [disabled]="this.selectedCustomer_Address === undefined"-->
                      <!--                                  [ngClass]="selectCustomerFlag === 'Button'+row._id ? 'secondary-color' : 'primary-color'"-->
                      <!--                                  (click)="selectCustomer(row,'Button'+row._id)">Select-->
                      <!--                          </button>-->
                      <!--                        </ng-template>-->
                      <!--                      </ngx-datatable-column>-->
                    </ngx-datatable>
                  </div>
                  <div class="row d-sm-block d-md-block d-lg-none" *ngFor="let row of customerList;">
                    <div class="col-sm-12 col-md-6 col-12">
                      <label class="label-font-weight">Name</label>
                      <p>{{ row.firstName }} {{ row.middleName }} {{ row.lastName }}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-12">
                      <label class="label-font-weight">Phone Number</label>
                      <p>{{ row.number }}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-12">
                      <label class="label-font-weight">Phone Number</label>
                      <p>{{ row.convertedDob === 'Invalid date' ? '' : row.convertedDob }}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-12">
                      <label class="label-font-weight">Address</label>
                      <ng-select id="{{row._id}}" [items]="row?.addresses" bindLabel="completeAddress"
                                 bindValue="_id" class="small-size"
                                 (change)="SelectedAddress($event,row)">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          <b>{{ item.completeAddress }}</b>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="form-group text-center">
                <button type="button" class="btn primary-color" awNextStep
                        [disabled]="selectedCustomer === undefined || selectedCustomer_Address === undefined">
                  Next
                  <span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step [stepTitle]="'Step 2'" [navigationSymbol]="{ symbol: '&#xf15b;', fontFamily: 'FontAwesome' }">
          <form>
            <h4 class="head text-center">USER SCRIPT</h4>
            <br/>
            <div class="row">
              <div class="col-12">
                <!--                <button class="btn btn-primary mt-2" (click)="open1(Opencontent,'sm')"><i class="ft-plus mr-1"></i>Add Script</button>-->
                <button class="btn primary-color mt-2" (click)="open1(ScriptOpen,'lg')"><i class="ft-plus mr-1"></i>Add
                  Medicine
                </button>
                <!--                <button class="btn secondary-btn mt-2 ml-2" (click)="open1(ScriptImage_Upload,'md')"><i-->
                <!--                  class="ft-upload mr-1"></i>-->
                <!--                  Upload Script Image/URL-->
                <!--                </button>-->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body" id="card_body">
                      <div class="table-responsive d-none d-lg-block d-sm-none">
                        <table class="table text-center m-0" *ngIf="this.Medicinerows.length > 0">
                          <thead>
                          <tr>
                            <th>Product Name</th>
                            <th></th>
                            <th>Token Number <sup class="text-danger">*</sup></th>
                            <th>Repeats<sup class="text-danger">*</sup></th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <!--                          <th>Repeats</th>-->
                            <!--                          <th>Instruction</th>-->
                            <!--                          <th>Note</th>-->
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let item of this.Medicinerows;let i = index;">
                            <!--                          <td>-->
                            <!--                            <div class="checkbox">-->
                            <!--                              <input type="checkbox" (click)="setItem(item,$event,token,repeats)"-->
                            <!--                                     [disabled]="token.value === '' || validation"-->
                            <!--                                     id="{{item.productName}}">-->
                            <!--                              <label for="{{item.productName}}"></label>-->
                            <!--                            </div>-->
                            <!--                          </td>-->
                            <td class="cursor-pointer">
                            <span style="text-decoration: underline;"
                                  (click)="showGlobalProd(item.globalProductId)"> {{ item.productName }}
                              <i class="float-right font-weight-bold font-small-1" ngbTooltip="Backorder"
                                 placement="top"
                                 [ngClass]="item.isBackOrder ? 'ft-circle round bg-warning text-warning mt-1' : ''"></i>
                            </span>
                            </td>
                            <td>
                            <span class="btn secondary-btn badge" ngbTooltip="Script Upload" placement="top"
                                  (click)="AddScriptImage(ScriptImage_Upload,'md',i)">
                             <i class="ft-upload"></i>
                            </span>
                            </td>
                            <td>
                              <div class="d-flex">
                                <input type="text" id="textbox{{i}}" class="form-control square text-UpperCase" #token
                                       value="{{item.token}}"
                                       (change)="OnChangeToken(item,token,'token')"
                                       (keyup)="validateText(token)">
                              </div>
                              <br>
                              <!--                            <p class="text-danger"  *ngIf="orderedItems[i].token === ''" id="valid{{i}}">Invalid Token</p>-->
                            </td>
                            <td>
                              <div class="d-flex">
                                <input type="number" class="form-control square text-uppercase" #repeats min="0"
                                       value="{{item.repeats === 0 ? '' : item.repeats}}"
                                       (change)="OnChangeToken(item,repeats,'repeat')"
                                       (keyup)="validateRepeats(repeats.value)">
                              </div>
                              <br>
                              <!--                            <p class="text-danger" *ngIf="orderedItems[i].repeats === '' || orderedItems[i].repeats === 0" id="validrepeats{{i}}">Invalid repeats</p>-->
                            </td>
                            <td>${{ item.productCost }}</td>
                            <td>
                              <div class="btn-group" id="{{item._id}}">
                                <a class="btn secondary-color px-1 py-1 font-medium-5" placement="top"
                                   ngbTooltip="Update quantity" (click)="decrease(item)">-</a>
                                <a class="btn secondary-color px-1 py-1">{{ item.quantity }}</a>
                                <a class="btn secondary-color px-1 py-1 font-medium-5" placement="top"
                                   ngbTooltip="Update quantity" (click)="increase(item)">+</a>
                              </div>
                            </td>
                            <td>
                              <button class="badge bg-light-danger btn"
                                      (click)="openConfimation(confirmModal1,item)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row d-lg-none d-sm-block" *ngFor="let item of this.Medicinerows;let i = index;">
                        <div class="col-sm-12 col-sm-12">
                          <label class="label-font-weight mt-1">Product Name</label>
                          <p class="cursor-pointer" style="text-decoration: underline;"
                             (click)="showGlobalProd(item.globalProductId)"> {{ item.productName }}
                            <i class="float-right font-weight-bold font-small-1" ngbTooltip="Backorder"
                               placement="top"
                               [ngClass]="item.isBackOrder ? 'ft-circle round bg-warning text-warning mt-1' : ''"></i>
                          </p>
                        </div>
                        <div class="col-sm-12 col-sm-12">
                          <label class="label-font-weight mt-1">Token <sup class="text-danger">*</sup></label>
                          <input type="text" id="textbox{{i}}" class="form-control square text-UpperCase" #token
                                 value="{{item.token}}"
                                 (change)="OnChangeToken(item,token,'token')"
                                 (keyup)="validateText(token)">
                        </div>
                        <div class="col-sm-12 col-sm-12">
                          <label class="label-font-weight mt-1">Script Info</label><br>
                          <span class="btn secondary-btn badge" ngbTooltip="Script Upload" placement="top"
                                (click)="AddScriptImage(ScriptImage_Upload,'md',i)">
                             <i class="ft-upload"></i>
                            </span>
                        </div>
                        <div class="col-sm-12 col-sm-12">
                          <label class="label-font-weight mt-1">Repeats <sup class="text-danger">*</sup></label>
                          <input type="number" class="form-control square text-uppercase" #repeats min="0"
                                 value="{{item.repeats === 0 ? '' : item.repeats}}"
                                 (change)="OnChangeToken(item,repeats,'repeat')"
                                 (keyup)="validateRepeats(repeats.value)">
                        </div>
                        <div class="col-sm-12 col-sm-12">
                          <label class="label-font-weight mt-1">Quantity</label><br>
                          <div class="btn-group" id="{{item._id}}">
                            <a class="btn secondary-color px-1 py-1 font-medium-5" placement="top"
                               ngbTooltip="Update quantity" (click)="decrease(item)">-</a>
                            <a class="btn secondary-color px-1 py-1">{{ item.quantity }}</a>
                            <a class="btn secondary-color px-1 py-1 font-medium-5" placement="top"
                               ngbTooltip="Update quantity" (click)="increase(item)">+</a>
                          </div>
                        </div>
                        <div class="col-sm-12 col-sm-12">
                          <label class="label-font-weight mt-1">Price</label>
                          <p>${{ item.productCost }}</p>
                        </div>
                        <div class="col-sm-12 col-sm-12">
                          <button class="btn-block bg-light-danger btn"
                                  (click)="openConfimation(confirmModal1,item)">
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group text-center space-20 mt-2">
              <button uiSref="work" type="button" class="btn secondary-color mr-1" awPreviousStep><span
                style="margin-right:10px;"><i class="ft-chevron-left"></i></span> Previous
              </button>
              <button type="button" class="btn primary-color" [disabled]="this.orderedItems.length === 0"
                      (click)="nextStep(wizard)">
                Next<span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step [stepTitle]="'Step 3'" [navigationSymbol]="{ symbol: '&#xf2bd;', fontFamily: 'FontAwesome' }">
          <h4 class="head text-center">ORDER SUMMARY</h4>
          <br/>
          <h5 class="font-weight-bold font-italic">Your Information</h5>
          <hr>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Status</label><br>
              <p class="badge bg-light-success btn"
                 [ngClass]="this.pharmacyUser?.Status === 'verified' ? 'bg-light-success' : 'bg-light-warning'">
                <i class="ft-check-circle mr-1"
                   [ngClass]="this.pharmacyUser?.Status === 'verified'? 'ft-check-circle' : 'ft-alert-triangle'"></i>{{ this.pharmacyUser?.Status === 'verified' ? 'Verified' : 'Unverified' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Name</label>
              <p>{{ this.selectedCustomer?.firstName }} {{ this.selectedCustomer?.middleName }} {{ this.selectedCustomer?.lastName }}</p>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Mobile Number</label>
              <p>{{ this.selectedCustomer?.number }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Email</label>
              <p>{{ this.selectedCustomer?.email }}</p>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Address</label>
              <p>
                <b>{{ this.selectedCustomer_Address?.addressType }}</b>&nbsp;{{ this.selectedCustomer_Address?.completeAddress }}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="scriptImage !== ''">
            <div class="col-12 col-md-12">
              <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                <i
                  *ngIf="showUrl"
                  class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                  (click)="downloadScript(scriptImage)"></i>
              </label><br>
              <div *ngIf="showUrl">
                <img class="cursor-pointer" src="{{this.scriptImage}}" alt="" width="60" height="40"
                     (click)="isCollapsed = !isCollapsed"
                     [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample1">
                <div id="collapseExample1" [ngbCollapse]="isCollapsed">
                  <img src="{{this.scriptImage}}" alt="" width="400" height="400">
                </div>
              </div>
              <div *ngIf="!showUrl">
                <p>{{ scriptImage }}</p>
              </div>
            </div>
          </div>
          <br>
          <p class="font-weight-bold font-italic font-medium-2">Your Script
            <button class="btn badge primary-btn float-right" (click)="open1(ScriptOpen,'lg','step-3')"><i
              class="ft-plus mr-1"></i>Add Medicine
            </button>
          </p>
          <hr>
          <div class="table-responsive d-none d-lg-block d-sm-none">
            <table class="table text-center m-0" *ngIf="this.orderedItems.length > 0">
              <thead>
              <tr>
                <th class="text-left">Product Name</th>
                <th class="text-left">Token <sup class="text-danger">*</sup></th>
                <th class="text-center">Script Info</th>
                <th class="text-left">Repeats <sup class="text-danger">*</sup></th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Actions</th>
                <!--              <th>Repeats</th>-->
                <!--              <th>Instruction</th>-->
                <!--              <th>Note</th>-->
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let medicine of this.orderedItems;let i = index;">
                <!--              <td>{{medicine | json}}</td>-->
                <td class="text-left">{{ medicine?.product_name }}
                  <i class="float-right font-weight-bold font-small-1" ngbTooltip="Backorder"
                     placement="top"
                     [ngClass]="medicine.isBackOrder ? 'ft-circle round bg-warning text-warning mt-1' : ''"></i>
                </td>
                <td class="text-left text-UpperCase">
                  <div class="d-flex">
                    <input type="text" id="textbox{{i}}" class="form-control square text-UpperCase" #token
                           value="{{ medicine?.token }}"
                           (change)="OnChangeToken(medicine,token,'token','step-3')"
                           (keyup)="validateText(token)">
                  </div>
                  <br>
                </td>
                <td class="text-center">
                  <img *ngIf="medicine?.scriptData?.uploadedScript !== '' && medicine?.scriptData?.type !== 'url'"
                       class="cursor-pointer"
                       src="{{ medicine?.scriptData?.uploadedScript }}"
                       alt="" width="60" height="40"
                       (click)="viewScript_image(medicine?.scriptData?.uploadedScript,view_script_image)">
                  <a *ngIf="medicine.scriptData.type === 'url'" target="_blank"
                     href="{{medicine?.scriptData?.uploadedScript}}" class="text-primary">
                  <span ngbTooltip="{{medicine?.scriptData?.uploadedScript}}" placement="top"
                        style="text-decoration: underline">Click Here</span>
                  </a>
                  <span *ngIf="medicine?.scriptData?.uploadedScript === ''" class="btn secondary-btn badge"
                        ngbTooltip="Script Upload" placement="top"
                        (click)="AddScriptImage(ScriptImage_Upload,'md',i)">
                             <i class="ft-upload"></i>
                            </span>
                </td>
                <td class="text-left">
                  <div class="d-flex">
                    <input type="number" class="form-control square text-uppercase" #repeats min="1"
                           value="{{medicine?.repeats === 0 ? '' : medicine?.repeats}}"
                           (change)="OnChangeToken(medicine,repeats,'repeat','step-3')"
                           (keyup)="validateRepeats(repeats.value)">
                  </div>
                </td>
                <td>
                  <div class="btn-group" id="{{medicine.product_name}}">
                    <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                       ngbTooltip="Update quantity" (click)="decrease(medicine,'step-3')">-</a>
                    <a class="btn secondary-btn px-1 py-1">{{ medicine.quantity }}</a>
                    <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                       ngbTooltip="Update quantity" (click)="increase(medicine,'step-3')">+</a>
                  </div>
                </td>
                <td>${{ medicine.price }}</td>
                <td>
                  <button class="badge bg-light-danger btn"
                          (click)="openConfimation(confirmModal1,medicine,'step-3')">
                    Remove
                  </button>
                </td>
                <!--              <td>{{medicine.repeats}}</td>-->
                <!--              <td>{{medicine.instruction}}</td>-->
                <!--              <td>{{medicine.note}}</td>-->
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row d-lg-none d-sm-block" *ngFor="let medicine of this.orderedItems;let i = index;">
            <div class="col-sm-12 col-sm-12">
              <label class="label-font-weight mt-1">Product Name</label>
              <p>{{ medicine?.product_name }}</p>
            </div>
            <div class="col-sm-12 col-sm-12">
              <label class="label-font-weight mt-1">Token <sup class="text-danger">*</sup></label>
              <input type="text" id="textbox{{i}}" class="form-control square text-UpperCase" #token
                     value="{{ medicine?.token }}"
                     (change)="OnChangeToken(medicine,token,'token','step-3')"
                     (keyup)="validateText(token)">
            </div>
            <div class="col-sm-12 col-sm-12">
              <label class="label-font-weight mt-1">Script Info</label>
              <br>
              <img *ngIf="medicine?.scriptData?.uploadedScript !== '' && medicine?.scriptData?.type !== 'url'"
                   class="cursor-pointer"
                   src="{{ medicine?.scriptData?.uploadedScript }}"
                   alt="" width="60" height="40"
                   (click)="viewScript_image(medicine?.scriptData?.uploadedScript,view_script_image)">
              <a *ngIf="medicine.scriptData.type === 'url'" target="_blank"
                 href="{{medicine?.scriptData?.uploadedScript}}" class="text-primary">
                  <span ngbTooltip="{{medicine?.scriptData?.uploadedScript}}" placement="top"
                        style="text-decoration: underline">Click Here</span>
              </a>
              <br>
              <span *ngIf="medicine?.scriptData?.uploadedScript === ''" class="btn secondary-btn badge"
                    ngbTooltip="Script Upload" placement="top"
                    (click)="AddScriptImage(ScriptImage_Upload,'md',i)">
                             <i class="ft-upload"></i>
                            </span>
            </div>
            <div class="col-sm-12 col-sm-12">
              <label class="label-font-weight mt-1">Repeats <sup class="text-danger">*</sup></label>
              <input type="number" class="form-control square text-uppercase" #repeats min="1"
                     value="{{medicine?.repeats === 0 ? '' : medicine?.repeats}}"
                     (change)="OnChangeToken(medicine,repeats,'repeat','step-3')"
                     (keyup)="validateRepeats(repeats.value)">
            </div>
            <div class="col-sm-12 col-sm-12">
              <label class="label-font-weight mt-1">Quantity</label><br>
              <div class="btn-group" id="{{medicine.product_name}}">
                <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                   ngbTooltip="Update quantity" (click)="decrease(medicine,'step-3')">-</a>
                <a class="btn secondary-btn px-1 py-1">{{ medicine.quantity }}</a>
                <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                   ngbTooltip="Update quantity" (click)="increase(medicine,'step-3')">+</a>
              </div>
            </div>
            <div class="col-sm-12 col-sm-12">
              <label class="label-font-weight mt-1">Price</label>
              <p>${{ medicine.price }}</p>
            </div>
            <div class="col-sm-12 col-sm-12">
              <button class="btn-block bg-light-danger btn"
                      (click)="openConfimation(confirmModal1,medicine,'step-3')">
                Remove
              </button>
            </div>
          </div>
          <br>
          <h5 class="font-weight-bold font-italic">Selected Pharmacy</h5>
          <hr>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Pharmacy Name</label>
              <p>{{ this.selectedPharmacy?.pharmacy_name }}</p>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Mobile Number</label>
              <p>{{ this.selectedPharmacy?.phoneNumber }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Email</label>
              <p>{{ this.selectedPharmacy?.email }}</p>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-4">
              <label class="font-weight-bold">Pharmacy Address</label>
              <p>{{
                  this.selectedPharmacy?.address + ' ' + this.selectedPharmacy?.suburb + ' '
                  + this.selectedPharmacy?.state + ' ' + this.selectedPharmacy?.postcode
                }}</p>
            </div>
          </div>
          <br>
          <h5 class="font-weight-bold font-italic">Delivery Type <sup class="text-danger">*</sup></h5>
          <hr>
          <div class="d-flex">
                <span class="badge bg-light-primary btn"
                      *ngIf="this.selectedPharmacy?.deliveryType === 'delivery' || this.selectedPharmacy?.deliveryType === 'both'"
                      [ngClass]="deliverModal.delivery ? 'bg-light-secondary' : 'bg-light-primary'"
                      (click)="openDeliveryModal(content4,'sm',false)"> <i
                  class="ft-truck mr-1"></i>Delivery</span>

            <span class="badge bg-light-primary btn ml-1"
                  *ngIf="this.selectedPharmacy?.deliveryType === 'pickup' || this.selectedPharmacy?.deliveryType === 'both'"
                  [ngClass]="deliverModal.pickup ? 'bg-light-secondary' : 'bg-light-primary'"
                  (click)="openDeliveryModal(content4,'md',true)"><i
              class="ft-shopping-bag mr-1"></i>Pickup</span>
          </div>
          <div class="form-group text-center space-20 mt-2">
            <button uiSref="work" type="button" class="btn secondary-color mr-1" awPreviousStep>
              <span style="margin-right:10px;"><i class="ft-chevron-left"></i></span> Previous
            </button>
            <button type="button" class="btn primary-color" [disabled]="lockButton"
                    (click)="confirmation(content3,'sm')">Submit
              <span style="margin-left:10px;"><i class="ft-check-circle"></i></span>
            </button>
          </div>
        </aw-wizard-step>

      </aw-wizard>

    </section>
  </div>
</ng-template>


<ng-template #content3 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-justify">Are you certain about proceeding with the order to the selected pharmacy?</p>
    <div class="text-center">
      <button class="btn secondary-btn" [disabled]="lockButton" (click)="placeOrder(modal)">Yes</button>
    </div>
  </div>
</ng-template>

<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>

<ng-template #content4 let-c="close" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ pickup ? 'Pickup' : 'Delivery' }}</h4>


    <button type="button" class="close" aria-label="Close" (click)="this.deliverModal.delivery = false;
     this.deliverModal.pickup = false; this.deliverTypeSelected = false;modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="pickup;else others">
      <p class="text-justify font-weight-bold text-center">Who is picking up?</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn" (click)="selectMe('Me')"
                [ngClass]="choiceMade === 'Me' ? 'secondary-color' :'primary-color'">
          Me
        </button>
        <button class="btn ml-2" (click)="selectMe('Carrier')"
                [ngClass]="choiceMade === 'Carrier' ? 'secondary-color' : 'primary-color'">Carer
        </button>
      </div>
      <div class="px-1 py-2" *ngIf="showAuthTPAForm">
        <h4>Authorized TPA Details</h4>
        <div class="row">
          <div class="col-md-6">
            <label>Full Name</label>
            <input type="text" name="fullName" class="form-control square" #fullname>
          </div>
          <div class="col-md-6">
            <label>Relationship</label>
            <select name="relationship" class="form-control square" #relType>
              <option value="mother">Mother</option>
              <option value="father">Father</option>
              <option value="spouse">Spouse</option>
              <option value="brother">Brother</option>
              <option value="sister">Sister</option>
              <option value="grandParent">GrandParent</option>
              <option value="son">Son</option>
              <option value="daughter">Daugther</option>
            </select>
          </div>
        </div>
        <p><b>Note:</b> TPA Must Provide Goverment issued ID on pickup that matches full Name</p>
        <div class="row">
          <div class="col-12 col-md-12 text-center">
            <label class="text-justify font-weight-bold">Pharmacy Address</label>
            <p>{{ this.selectedPharmacy.pharmacy_name }} <br>
              {{
                this.selectedPharmacy?.address + ' ' + this.selectedPharmacy?.suburb + ' '
                + this.selectedPharmacy?.state + ' ' + this.selectedPharmacy?.postcode
              }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 text-center px-2 py-2">
            <button class="btn primary-color" (click)="fillData(fullname.value,relType.value,modal)">Submit</button>
          </div>
        </div>
      </div>
      <div class="row mt-1" *ngIf="choiceMade === 'Me'">
        <div class="col-12 col-md-12 text-center">
          <label class="text-justify font-weight-bold">Pharmacy Address</label>
          <p>{{ this.selectedPharmacy.pharmacy_name }} <br>
            {{
              this.selectedPharmacy?.address + ' ' + this.selectedPharmacy?.suburb + ' '
              + this.selectedPharmacy?.state + ' ' + this.selectedPharmacy?.postcode
            }}</p>
        </div>
      </div>
      <div class="row" *ngIf="choiceMade === 'Me'">
        <div class="col-12 col-md-12 text-center px-2 py-2">
          <button class="btn primary-color" (click)="modal.dismiss('Cross-Click')">Submit</button>
        </div>
      </div>
    </div>
    <ng-template #others>

      <p class="text-justify font-weight-bold text-danger"> Delivery Fee:
        <span>${{ this.selectedPharmacy?.freightCharge }}</span></p>
      <p class="text-justify"><b>Your Delivery Address is:</b> <br>{{
          this.selectedCustomer_Address?.address + ' ' + this.selectedCustomer_Address?.suburb + ' '
          + this.selectedCustomer_Address?.state + ' ' + this.selectedCustomer_Address?.postcode
        }} </p>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-sm-12">
          <label>Delivery Instructions</label>
          <textarea class="form-control square" cols="10" rows="2" #deliveryInstruction>
          </textarea>
        </div>
      </div>
      <div class="text-center mt-1">
        <button class="btn primary-color" (click)="confirm(deliveryInstruction.value,modal)">Confirm</button>
      </div>
    </ng-template>
  </div>
</ng-template>


<ng-template #confirmModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Medicine</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to remove this Medicine?</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="remove(modal)">Delete</button>
        <button class="btn btn-secondary ml-2" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #AddCustomer let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Customer</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="userRegisteration" (ngSubmit)="onSubmit(modal,checkbox)">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-1">First Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-1"
                     formControlName="firstName"
              >
              <p class="text-danger" *ngIf="submitted && rf.firstName.invalid">First Name is required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-2">Middle Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-2"
                     formControlName="middleName">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-3">Last Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-3" formControlName="lastName">
              <p class="text-danger" *ngIf="submitted && rf.lastName.invalid">Last Name is required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-5">Phone Number</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-5" formControlName="number"
                     maxlength="10" minlength="8">
              <p class="text-danger" *ngIf="submitted && rf.number.invalid">Phone number is required</p>
              <p class="text-danger" *ngIf="rf.number.hasError('pattern')">Invalid Phone number</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Email</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-4" formControlName="email">
              <p class="text-danger" *ngIf="submitted && rf.email.invalid">Email is required</p>
              <p class="text-danger" *ngIf="rf.email.hasError('email')">Invalid email</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Date of Birth</label>
            <div class="col-md-9">
              <div class="input-group calendar-width">
                <input class="form-control square" (dateSelect)="changeDob($event)"
                       [minDate]="{year: 1901, month:1, day: 1}"
                       [(ngModel)]="model" [ngModelOptions]="{standalone: true}" name="dp" ngbDatepicker
                       #d2="ngbDatepicker">
                <div class="input-group-append">
                  <div class="input-group-text" (click)="d2.toggle()">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                  </div>
                </div>
              </div>
              <p class="text-danger" *ngIf="submitted && model === null">Date of Birth is required</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Password</label>
            <div class="col-md-9">
              <div class="input-group">
                <input type="password" class="form-control square" #passsword formControlName="password">
                <div class="input-group-prepend">
                  <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                    [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <p class="text-danger" *ngIf="submitted && rf.password.invalid">Password is required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Confirm Password</label>
            <div class="col-md-9">
              <div class="input-group">
                <input type="password" class="form-control square" #confirmpassword formControlName="confirm_password">
                <div class="input-group-prepend">
                  <span class="input-group-text cursor-pointer"
                        (click)="showPassword(confirmpassword,'confirm_password')"><i
                    [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <p class="text-danger" *ngIf="submitted && rf.confirm_password.invalid">Confirm Password is required</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Address Type</label>
          <select formControlName="addressType" id="" class="form-control square">
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Others">Others</option>
          </select>
          <p class="text-danger" *ngIf="submitted && rf.addressType.invalid">Address Type is required</p>
        </div>
        <div class="col-md-6 col-12">
          <label>Address</label>
          <input type="text" class="form-control square" formControlName="address">
          <p class="text-danger" *ngIf="submitted && rf.address.invalid">Address is required</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-12">
          <label>Suburb</label>
          <input type="text" class="form-control square" formControlName="suburb">
          <p class="text-danger" *ngIf="submitted && rf.suburb.invalid">Suburb is required</p>
        </div>
        <div class="col-md-6 col-12">
          <label>State</label>
          <ng-select formControlName="state" placeholder="Select State" [(ngModel)]="selectedState">
            <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
          </ng-select>
          <p class="text-danger" *ngIf="submitted && rf.state.invalid">State is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Postcode</label>
          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
          <p class="text-danger" *ngIf="submitted && rf.postcode.invalid">Postcode is required</p>
          <p class="text-danger" *ngIf="userRegisteration.controls['postcode'].hasError('pattern')">Invalid postcode</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <p class="checkbox text-center text-capitalize">
            <input type="checkbox" id="acceptTerms" #checkbox>
            <label for="acceptTerms"></label>
            By checking this box, You have permission to add this user and access their prescription information
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <button type="submit" class="btn primary-btn mb-2"><i class="ft-check-square mr-1"></i>Save</button>
        </div>
      </div>
    </form>
    <!--    <form [formGroup]="addressForm" (ngSubmit)="submitAddress(modal)" >-->
    <!--      <h4>Customer Address Details</h4>-->
    <!--      <div class="row">-->
    <!--        <div class="col-6">-->
    <!--          <label>Address</label>-->
    <!--          <input type="text" class="form-control square" formControlName="address">-->
    <!--          <p class="text-danger" *ngIf="submitted && addressForm.controls['address'].invalid">Address is required</p>-->
    <!--        </div>-->
    <!--        <div class="col-6">-->
    <!--          <label>Suburb</label>-->
    <!--          <input type="text" class="form-control square" formControlName="suburb">-->
    <!--          <p class="text-danger" *ngIf="submitted && addressForm.controls['suburb'].invalid">Suburb is required</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="row">-->
    <!--        <div class="col-6">-->
    <!--          <label>State</label>-->
    <!--          <input type="text" class="form-control square" formControlName="state">-->
    <!--          <p class="text-danger" *ngIf="submitted && addressForm.controls['state'].invalid">State is required</p>-->
    <!--        </div>-->
    <!--        <div class="col-6">-->
    <!--          <label>Postcode</label>-->
    <!--          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">-->
    <!--          <p class="text-danger" *ngIf="submitted && addressForm.controls['postcode'].invalid">Postcode is required</p>-->
    <!--          <p class="text-danger" *ngIf="addressForm.controls['postcode'].hasError('pattern')">Invalid postcode</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="row mt-2">-->
    <!--        <div class="col-12 text-right">-->
    <!--          <button class="btn btn-primary">Submit</button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </form>-->
  </div>
</ng-template>


<ng-template #dispensemodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Dispensed Orders</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="closeDispenseModal(modal)"></span>
  </div>
  <div class="modal-body">
    <div *ngFor="let order of this.shippedOrders;let i = index">
      <div class="row appointmentWrapper">
        <div class="col-3 pr-0">
          <div class="datatable-header-cell-template-wrap mt-2 doctorNameWarpper"><span
            class="datatable-header-cell-wrapper ng-star-inserted"><span
            class="datatable-header-cell-label draggable doctorName">Customer</span></span>
          </div>
          <div class="font-weight-bold">{{ order.name }}</div>
        </div>
        <div class="col-9 pl-0">
          <table class="table">
            <tr>
              <th>Token</th>
              <th>Number of Repeats</th>
              <th>Dispense Interval (days)</th>
            </tr>
            <tr *ngFor="let medi of order.orderedItem;let j = index;">
              <td>{{ medi.token }}</td>
              <td>
                <p>
                  <input type="number" value="{{medi.repeats}}" min="0"
                         id="repeats{{medi.token}}" #repeats class="form-control square">
                </p>
              </td>
              <td>
                <input type="number" class="form-control square" min="0"
                       id="duration{{medi.token}}" #duration_interval
                       (keyup)="fillDetails(order,medi,repeats.value,duration_interval.value)"
                >
              </td>
              <!--              <td>-->
              <!--                <div class="checkbox">-->
              <!--                  <input type="checkbox" class="form-control" #checkbox-->
              <!--                         [disabled]="duration_interval.value === ''"-->
              <!--                         id="checkbox{{order.orderNo}}{{i+j}}"-->
              <!--                         (change)="fillDetails(order,medi,$event,repeats.value,duration_interval.value,checkbox)">-->
              <!--                  <label for="checkbox{{order.orderNo}}{{i+j}}"></label>-->
              <!--                </div>-->
              <!--              </td>-->
            </tr>
          </table>
        </div>
      </div>
    </div>
    <button class="btn secondary-color float-right" [disabled]="disablebtn"
            (click)="sendDispense_Orders(modal)">Submit
    </button>
  </div>
</ng-template>
<ng-template #partialShippedModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Partial Shipment</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross-Click');"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3 col-12">
        <label>Customer</label>
        <div class="font-weight-bold">{{ selectedOrder?.name }}</div>
      </div>
      <div class="col-md-9 col-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Product Name</th>
              <th>Token</th>
              <th>Quantity</th>
              <th>Shipped</th>
              <th>Balance</th>
              <th>To Ship</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of this.orderedItems;let i = index;">
              <td>{{ item?.product_name }}</td>
              <td>{{ item?.token }}</td>
              <td>{{ item?.quantity }}</td>
              <td>{{ item.shipped }}</td>
              <td>{{ item.tempbalance }}</td>
              <td>
                <input type="number"
                       (keyup)="updateItemQuantity(item,partialQuantity)"
                       [disabled]="item.balance === 0"
                       [max]="item.balance" min="1" #partialQuantity value="">
              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="showForm && partialShippments.length > 0">
      <h4 class="modal-title ml-2 mt-1">Enter Shipping Details</h4>
      <div class="col-md-12 col-12">
        <form [formGroup]="carrierForm" (ngSubmit)="Complete_carrier(modal,'partialShippment',false)">
          <div class="row mt-1">
            <div class="col-md-3 col-12">
              <label>Carrier Number</label>
              <input type="text" class="form-control square" formControlName="carrier_number">
              <p *ngIf="submitted && carrierForm.controls['carrier_number'].invalid" class="text-danger">Carrier Number
                is required</p>
            </div>
            <div class="col-md-3 col-12">
              <label>Connote Number</label>
              <input type="text" class="form-control square" formControlName="connate_num">
              <p *ngIf="submitted && carrierForm.controls['connate_num'].invalid" class="text-danger">Connote number is
                required</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12 text-center">
              <button class="btn secondary-btn btn mt-2" type="submit"><i class="ft-check-square mr-2"></i>Complete
              </button>
            </div>
          </div>
        </form>
      </div>

      <!--      &lt;!&ndash;      Additional Cost&ndash;&gt;-->
      <!--      <h4 class="modal-title ml-2 mt-1" *ngIf="showForm && extraChargeBox.checked">Additional Cost</h4>-->
      <!--      <div class="col-md-12 col-12" *ngIf="showForm && extraChargeBox.checked">-->
      <!--        <div class="row">-->
      <!--          <div class="col-md-3 col-12">-->
      <!--            <label>Additional Cost</label>-->
      <!--            <div class="input-group">-->
      <!--              <div class="input-group-prepend">-->
      <!--                <span class="input-group-text"><i class="ft-dollar-sign"></i></span>-->
      <!--              </div>-->
      <!--              <input type="text" class="form-control" #extraCharge (keyup)="validateAmount(extraCharge)">-->
      <!--            </div>-->
      <!--            <p class="text-danger" *ngIf="submitted && extraCharge.value.trim() === ''">Additional Cost is required</p>-->
      <!--            <p class="text-danger" *ngIf="validation">Invalid Additional Cost</p>-->
      <!--          </div>-->
      <!--          <div class="col-md-3 col-12">-->
      <!--            <label>Description</label>-->
      <!--            <textarea cols="30" rows="1" #description class="form-control"></textarea>-->
      <!--            <p class="text-danger" *ngIf="submitted && description.value.trim() === ''">Description is required</p>-->
      <!--          </div>-->
      <!--          <div class="col-md-3 col-12">-->
      <!--            <button class="btn primary-color mt-3" type="button"-->
      <!--                    (click)="Complete_carrier(modal,'partialShippment',extraChargeBox.checked,extraCharge.value,description.value)">-->
      <!--              <i class="ft-check-circle mr-1"></i>Submit-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</ng-template>

<ng-template #Opencontent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="scriptForm" (ngSubmit)="add(smallModal,Opencontent)">
      <h5 class="head text-center">Enter Token Number</h5>
      <br/>
      <div class="row">
        <div class="col-md-12 col-12">
          <label class="form-control-label">Token Number</label>
          <input class="form-control input-md token"
                 formControlName="token" type="text">
          <p *ngIf="scriptForm.controls['token'].hasError('pattern')" class="text-danger">Invalid Token number</p>
          <p *ngIf="submitted && scriptForm.controls['token'].hasError('required')" class="text-danger">Token is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12 px-2 py-2 text-center">
          <button type="submit" class="btn btn-success" (click)="d('Cross click')">Add<span style="margin-left:10px;"><i
            class="ft-plus"></i></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--Opencontent-->
<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Warning!</h4>
  </div>
  <div class="modal-body text-center">
    <p>If you have a paper script or token, make sure to store it securely or dispose it properly when no longer
      needed.</p>
    <div class="text-center">
      <button class="btn btn-success" (click)="d('Cross click')">OK</button>
    </div>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>

<ng-template #ScriptImage_Upload let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Script</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-12">
        <select class="form-control square" (change)="OnChangeUploadType($event)">
          <option selected disabled>Select Type</option>
          <option value="image">Image</option>
          <option value="url">Url</option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="uploadType === 'image'">
      <div class="col-md-12 col-12">
        <input type="file" accept="image/png,image/jpg,image/jpeg" id="scriptupload" class="form-control d-none"
               (change)="upload($event,modal)">
        <label class="btn secondary-btn ml-2 mt-2" for="scriptupload"><i class="ft-camera mr-1"></i>Upload Script
          Image</label>
      </div>
    </div>
    <div class="row mt-2" *ngIf="uploadType === 'url'">
      <div class="col-md-12 col-12">
        <div class="d-flex">
          <input type="url" class="form-control square" #scriptUrl>
          <button class="btn ml-1 secondary-btn"
                  (click)="paste(scriptUrl.value,modal)">Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmationModal_Approval let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>{{ confirmationMessage }}</p>
    <div class="text-center">
      <button class="btn btn-danger"
              *ngIf="confirmationMessage === 'Please confirm that you would like to Accept this Order?'"
              (click)="acceptOrderForm('pending',modal)">Yes
      </button>
      <button class="btn btn-danger"
              *ngIf="confirmationMessage === 'Please confirm that you would like to Approve this Order?'"
              (click)="acceptManual(modal,selectedOrder)">Yes
      </button>
      <button class="btn btn-danger"
              *ngIf="confirmationMessage === 'Please confirm that you would like to Approve this Customer?'"
              (click)="verifyCustomer('verified',modal)">
        Yes
      </button>
      <button class="btn btn-danger"
              *ngIf="confirmationMessage === 'Are you certain you wish to proceed with a refund?'"
              (click)="moveToCancel(modal)">Yes
      </button>
      <button class="btn btn-danger"
              *ngIf="confirmationMessage === 'Do you want to delete this category?'"
              (click)="deleteIssueCategory(modal)">Yes
      </button>
      <button class="btn btn-danger"
              *ngIf="confirmationMessage === 'Please confirm that you would like to Complete this Order?'"
              (click)="updateOrderToComplete(modal)">Yes
      </button>
    </div>
  </div>
</ng-template>


<ng-template #issueCategory_Modal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"> Issues Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <button (click)="showForm = !showForm" class="btn badge secondary-btn float-right"><i class="ft-plus mr-1"></i>Create
      Category
    </button>
    <div class="row">
      <div class="col-md-12 col-12">
        <form *ngIf="showForm" (ngSubmit)="createIssueCategory(categoryName)">
          <div class="row mt-2">
            <div class="col-md-6 col-12">
              <label>Category Name</label>
              <input type="text" class="form-control" #categoryName>
              <p class="text-danger" *ngIf="submitted && categoryName.value === ''">Category Name is required</p>
            </div>
            <div class="col-md-3 col-12 pt-3">
              <button type="submit" class="btn primary-btn">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="table-responsive" *ngIf="isOrderData !== 'orderData'">
      <table class="table mt-2">
        <thead class="thead-light">
        <tr>
          <th>Sl.No</th>
          <th>Category Name</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of issuesCatgory;let i = index;">
          <td>{{ i + 1 }}</td>
          <td>{{ item.categoryName }}</td>
          <td>
            <span class="ft-trash text-danger cursor-pointer"
                  (click)="openDeleteModal(confirmationModal_Approval, item)"></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="isOrderData === 'orderData'">
      <div class="row mt-1" *ngIf="!showForm">
        <div class="col-md-12 col-12">
          <label>Select Category</label>
          <div class="d-flex">
            <ng-select [items]="issuesCatgory" bindLabel="categoryName" [(ngModel)]="SelectedIssueCategory"
                       bindValue="_id" class="size"
                       (change)="OnChangeCategory($event)">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <b>{{ item.categoryName }}</b>
              </ng-template>
            </ng-select>
            <button class="badge bg-light-warning btn ml-1"
                    (click)="reportIssue('true','Issue Reported Successfully ')">
              <i class="ft-alert-triangle mr-1"></i> Report Issue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--Extra payment Charge-->
<ng-template #extraChargeModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Additional Cost</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-12">
        <label>Additional Cost</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="ft-dollar-sign"></i></span>
          </div>
          <input type="text" class="form-control" #extraCharge (keyup)="validateAmount(extraCharge)">
        </div>
        <p class="text-danger" *ngIf="submitted && extraCharge.value.trim() === ''">Additional Cost is required</p>
        <p class="text-danger" *ngIf="validation">Invalid Additional Cost</p>
      </div>
      <div class="col-md-12 col-12">
        <label>Description</label>
        <textarea cols="30" rows="3" #description class="form-control"></textarea>
        <p class="text-danger" *ngIf="submitted && description.value.trim() === ''">Description is required</p>
      </div>
      <div class="col-md-12 col-12 px-1 py-1">
        <button class="btn primary-color float-right mt-2" type="button"
                [disabled]="disablebtn"
                (click)="makeExtraCharge(extraCharge,description,modal)"><i class="ft-check-circle mr-1"></i>Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmMail let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Resend Payment Link</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to resend the payment link?</p>
    <button class="btn btn-danger" (click)="initPayment('true')">Yes</button>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #productsShipped let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Shipped Products - #{{ childOrder.orderNo }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table items-table">
        <thead>
        <tr>
          <!--          <th class="text-left">QR Code</th>-->
          <th>Token Number</th>
          <th>Brand Name</th>
          <th>Product Name</th>
          <th>Ordered Quantity</th>
          <th>Shipped Quantity</th>
          <th>Remaining Quantity</th>
        </tr>
        </thead>
        <tr *ngFor="let item of this.childOrder?.orderedItem;let i = index;">
          <td><span (click)="copyToken(item?.token)" class="text-UpperCase"><i
            class="cursor-pointer ft-copy mr-1 text-primary"
            ngbTooltip="Token copied"
            triggers="click:blur"></i></span>{{ item?.token }}
          </td>
          <td>{{ item?.product_brand_name }}</td>
          <td class=" cursor-pointer" (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}</td>
          <td>{{ item?.quantity }}</td>
          <td>{{ item?.shippedQuantity }}</td>
          <td>{{ item?.remainingQuantity }}</td>
        </tr>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>


<ngx-spinner></ngx-spinner>
